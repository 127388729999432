import React, {FC} from 'react'
import {Field, ErrorMessage} from 'formik'

const Step2: FC = () => {
  return (
    <div className='w-100'>
      <div className='fv-row mb-10'>
        <label className='form-label required'>نام فروشگاه</label>

        <Field name='businessName' className='form-control form-control-lg form-control-solid' />
        <div className='text-danger mt-2'>
          <ErrorMessage name='businessName' />
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center form-label'>
          <span className='required'>نام کاربری فروشگاه</span>
        </label>

        <Field
          name='businessUsername'
          className='form-control form-control-lg form-control-solid'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='businessUsername' />
        </div>

        <div className='form-text'>
          نام کاربری باید ترکیبی از حروف انگلیسی و اعداد باشد.
        </div>
      </div>




      
    </div>
  )
}

export {Step2}
