import {FC} from 'react'
import {KTIcon} from '../../../helpers'

const Chat: FC = () => {
  return (
    <>
      <a
        href='/apps/chat'
        className='d-flex align-items-stretch'
      >
        <div
          className='d-flex align-items-center'
        >
          <div className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'>
            <KTIcon iconName='messages' className='fs-1' />
          </div>
        </div>
      </a>
    </>
  )
}

export {Chat}
