import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { getShops } from './_requests'
import { ShopModel } from './Models'
import debounce from 'lodash.debounce'

const DEFAULT_TEHRAN_COORDS: [number, number] = [35.6892, 51.389]
const DEFAULT_IRAN_BOUNDS: [[number, number], [number, number]] = [
  [25.0, 44.0], // جنوب غربی ایران
  [39.0, 63.0], // شمال شرقی ایران
]

// کامپوننت برای کنترل موقعیت و زوم نقشه
const MapController: React.FC<{ bounds: [[number, number], [number, number]] }> = ({ bounds }) => {
  const map = useMap()

  useEffect(() => {
    if (bounds) {
      map.fitBounds(bounds, { animate: true })
    }
  }, [bounds, map])

  return null;
}

const Map: React.FC = () => {
  const [shops, setShops] = useState<ShopModel[]>([])
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [mapBounds, setMapBounds] = useState<[[number, number], [number, number]]>(DEFAULT_IRAN_BOUNDS)

  const customIcon = new L.Icon({
    iconUrl: 'https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon.png',
    iconSize: [21, 32],
    iconAnchor: [16, 32],
    popupAnchor: [-5, -32],
  })

  // تابع درخواست به بک‌اند
  const fetchShops = async (query: string = '') => {
    try {
      const response = await getShops({ name: query })
      setShops(response.data)

      // اگر داده‌هایی برگشتند، نقشه را روی محدوده آن‌ها تنظیم کن
      if (response.data.length > 0) {
        const bounds = response.data.map((shop) => [shop.lat, shop.lng] as [number, number])
        const leafletBounds = L.latLngBounds(bounds)
        setMapBounds([[leafletBounds.getSouthWest().lat, leafletBounds.getSouthWest().lng],
        [leafletBounds.getNorthEast().lat, leafletBounds.getNorthEast().lng]])
      } else {
        setMapBounds(DEFAULT_IRAN_BOUNDS)
      }

    } catch (error) {
      console.error('Error fetching shops:', error)
    }
  }

  // هندلر جستجو با تأخیر (debounced)
  const handleSearch = debounce((query: string) => {
    setSearchQuery(query)
    fetchShops(query)
  }, 300)

  // بارگذاری اولیه داده‌ها
  useEffect(() => {
    fetchShops()
  }, [])

  return (
    <div className="map-page card p-2">
      {/* فیلد جستجو */}
      <div className="search-bar">
        <input
          type="text"
          placeholder="جستجو فروشگاه..."
          onChange={(e) => handleSearch(e.target.value)}
          onFocus={() => setMapBounds(DEFAULT_IRAN_BOUNDS)}
          className="form-control"
        />
      </div>

      {/* نقشه */}
      <div className="map-container" style={{ height: '500px', width: '100%' }}>
        <MapContainer
          center={DEFAULT_TEHRAN_COORDS}
          zoom={11}
          style={{ height: '100%', width: '100%' }}
          bounds={mapBounds}
        >
          <MapController bounds={mapBounds} />
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
 
          {shops.map((shop) => (
            <Marker key={shop.id} position={[shop.lat, shop.lng]} icon={customIcon}>
              <Popup>
                <strong><Link to={`/shop/${shop.username}`}>{shop.name}</Link></strong>
                <br />
                {shop.address}
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </div>
    </div>
  )
}

export { Map }
