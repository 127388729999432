import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const GET_MODAL_URL = `${API_URL}/get_modal_news`



export function getModalNews() {
  return axios.get(GET_MODAL_URL, {});
}
