import axios from 'axios'
import { UserModel } from '../../modules/auth/core/_models'

const API_URL = process.env.REACT_APP_API_URL


export const UPDATE_USER_URL = `${API_URL}/update_user`
export const UPDATE_PASS_URL = `${API_URL}/update_user_password`


export function updateUser(data: UserModel) {
  return axios.post(UPDATE_USER_URL, data);
}

export function updateUserPassword(data: any) {
  return axios.post(UPDATE_PASS_URL, data);
}