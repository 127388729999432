import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const POST_VISIT_URL = `${API_URL}/store-visit`



export function addVisit(data: any) {
  return axios.post(POST_VISIT_URL, {
    page_url: data,
  });
}

