import axios from 'axios'
import { ShopModel, PostModel, ProductModel, ParentCategoryModel } from './ProfileModels'

const API_URL = process.env.REACT_APP_API_URL

export const GET_SHOP_URL = `${API_URL}/get_shop`
export const UPDATE_SHOP_URL = `${API_URL}/update_shop`
export const ADD_POST_URL = `${API_URL}/add_post`
export const DEL_POST_URL = `${API_URL}/del_post`
export const GET_POSTS_URL = `${API_URL}/get_posts`
export const GET_CAT_URL = `${API_URL}/get_categories`
export const ADD_PROUDCT_URL = `${API_URL}/add_proudct`
export const ADD_VIP_URL = `${API_URL}/add_vip_proudct`
export const DEL_PROUDCT_URL = `${API_URL}/del_proudct`
export const GET_PROUDCTS_URL = `${API_URL}/get_proudcts`
export const CHANGE_FOLLOW_URL = `${API_URL}/change_follow`


export function getShopByUsername(username: string) {
  return axios.post<ShopModel>(GET_SHOP_URL, {
    username: username,
  })
} 

export function updateShop(shopData: ShopModel) {
  return axios.post(UPDATE_SHOP_URL, shopData);
}

export function addPost(data: any) {
  return axios.post(ADD_POST_URL, data);
}

export function getShopPosts(shopId: string) {
  return axios.post<PostModel>(GET_POSTS_URL, {
    shopId: shopId,
  })
}

export function addVipProduct(postId: string) {
  return axios.post(ADD_VIP_URL, {
    productId: postId,
  })
}

export function deleteShopPost(postId: string) {
  return axios.post(DEL_POST_URL, {
    postId: postId,
  })
}

export function addProduct(data: any) {
  return axios.post(ADD_PROUDCT_URL, data);
}

export function getShopProducts(shopId: string) {
  return axios.post<ProductModel>(GET_PROUDCTS_URL, {
    shopId: shopId,
  })
}

export function deleteShopProduct(productId: string) {
  return axios.post(DEL_PROUDCT_URL, {
    productId: productId,
  })
}

export function getShopCategories() {
  return axios.post<ParentCategoryModel[]>(GET_CAT_URL, {
  })
}

export function changeFollow(shopId: string) {
  return axios.post(CHANGE_FOLLOW_URL, {
    shopId: shopId,
  })
}