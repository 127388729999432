import axios from 'axios'
import { ProductModel } from './Models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_SEARCH_URL = `${API_URL}/get_searches`
export const GET_FOLLOWED_URL = `${API_URL}/get_followed_products`




export function getSearches(data: any) {
  return axios.post<ProductModel[]>(GET_SEARCH_URL, data);
}

export function getFollowedProducts() {
  return axios.post<ProductModel[]>(GET_FOLLOWED_URL, {});
}
