import React, { FC, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Step1 } from './steps/Step1'
import { Step2 } from './steps/Step2'
import { Step3 } from './steps/Step3'
import { Step4 } from './steps/Step4'
import { StepperComponent } from '../../../../_metronic/assets/ts/components'
import { Form, Formik, FormikValues } from 'formik'
import { createAccountSchemas, ICreateAccount, inits } from './CreateShopWizardHelper'
import { newShop } from './_requests'
import Swal from 'sweetalert2'

const NewShop: FC = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState<ICreateAccount>(inits)
  const [isSubmitButton, setSubmitButton] = useState(false)
  const navigate = useNavigate()

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totalStepsNumber)
  }

  const submitStep = async (values: ICreateAccount, actions: FormikValues) => {
    if (!values.termsAccepted && stepper?.current?.currentStepIndex == 4) {
      Swal.fire({
        title: 'خطا',
        text: 'مطالعه و پذیرش قوانین و شرایط الزامی است.',
        icon: 'warning',
        confirmButtonText: 'بسیار خب!'
      })
      return
    }

    if (!stepper.current) {
      return
    }

    if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
      stepper.current.goNext()
    } else {
      try {

        // ارسال داده‌ها به بک‌اند
        const response = await newShop(
          values.type,
          values.businessName,
          values.businessUsername,
          values.state,
          values.city,
          values.phone,
          values.address,
          values.latitude,
          values.longitude
        )

        // دریافت پیام موفقیت آمیز از بک‌اند
        const successMessage = response.data.message

        // نمایش پیام موفقیت آمیز
        Swal.fire({
          title: 'موفق',
          text: successMessage, // نمایش پیام دریافت شده از بک‌اند
          icon: 'success',
          timer: 2000,
          confirmButtonText: 'بزن بریم!',
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(`/shop/${values.businessUsername}`) // هدایت به صفحه فروشگاه
          }
        })

        actions.resetForm();

        setTimeout(() => {
          navigate(`/shop/${values.businessUsername}`);
        }, 2100);

      } catch (error: any) {
        // بررسی وجود ارورهای اعتبارسنجی
        if (error.response) {
          // اگر ارور مربوط به عدم امکان ایجاد فروشگاه جدید باشد
          if (error.response.status === 400 && error.response.data.message) {
            Swal.fire({
              title: 'خطا',
              text: error.response.data.message, 
              icon: 'error',
              confirmButtonText: 'بسیار خب!',
            });
          } else if (error.response.data.errors) {
            const errorMessages = Object.values(error.response.data.errors)
              .flat()
              .join(', ');

            Swal.fire({
              title: 'خطا در ثبت',
              text: errorMessages,
              icon: 'error',
              confirmButtonText: 'بسیار خب!',
            });
          } else {
            Swal.fire({
              title: 'خطای ناشناخته',
              text: 'خطایی در ایجاد فروشگاه رخ داد. لطفا دوباره تلاش کنید.',
              icon: 'error',
              confirmButtonText: 'بسیار خب!',
            });
          }
        } else {
          Swal.fire({
            title: 'خطای ناشناخته',
            text: 'خطایی در ایجاد فروشگاه رخ داد. لطفا دوباره تلاش کنید.',
            icon: 'error',
            confirmButtonText: 'بسیار خب!',
          });
        }
      }
    }

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totalStepsNumber);
    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1]);
  }



  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <div className='card'>
      <div className='card-body'>
        <div
          ref={stepperRef}
          className='stepper stepper-links d-flex flex-column pt-15'
          id='kt_create_account_stepper'
        >
          <div className='stepper-nav mb-5'>
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>1. نوع فروشگاه</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>2. اطلاعات فروشگاه</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>3. آدرس فروشگاه</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>4. ثبت فروشگاه</h3>
            </div>
          </div>

          <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
            {() => (
              <Form className='mx-auto mw-600px w-100 pt-15 pb-10' id='kt_create_account_form'>
                <div className='current' data-kt-stepper-element='content'>
                  <Step1 />
                </div>
                <div data-kt-stepper-element='content'>
                  <Step2 />
                </div>
                <div data-kt-stepper-element='content'>
                  <Step3 />
                </div>
                <div data-kt-stepper-element='content'>
                  <Step4 />
                </div>

                <div className='d-flex flex-stack pt-15'>
                  <div className='mr-2'>
                    <button
                      onClick={prevStep}
                      type='button'
                      className='btn btn-lg btn-light-primary me-3'
                      data-kt-stepper-action='previous'
                    >
                      بازگشت
                    </button>
                  </div>

                  <div>
                    <button type='submit' className='btn btn-lg btn-primary me-3'>
                      <span className='indicator-label'>
                        {!isSubmitButton && 'ادامه'}
                        {isSubmitButton && 'ثبت'}
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export { NewShop }
