import React, { useEffect, useState } from 'react';
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers';
import { getShopPosts } from '../../pages/shop/profile/_requests';
import { PostModel } from '../../pages/shop/profile/ProfileModels';

const Blog: React.FC = () => {
  const [posts, setPosts] = useState<PostModel[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [expandedPosts, setExpandedPosts] = useState<Set<string>>(new Set()); // تغییر به Set<string>

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        setLoading(true);
        const response = await getShopPosts('1');
        if (Array.isArray(response.data)) {
          setPosts(response.data);
        } else if (response.data) {
          setPosts([response.data]);
        } else {
          setPosts([]);
        }
      } catch (error) {
        setError('خطا در بارگذاری مقالات. لطفاً دوباره تلاش کنید.');
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  const toggleExpand = (postId: string) => {
    setExpandedPosts((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(postId)) {
        newSet.delete(postId);
      } else {
        newSet.add(postId);
      }
      return newSet;
    });
  };

  const renderContent = (content: string, isExpanded: boolean) => {
    const plainText = content.replace(/<[^>]+>/g, '');
    return isExpanded ? (
      <div dangerouslySetInnerHTML={{ __html: content }}></div>
    ) : (
      <div>
        {plainText.length > 100 ? `${plainText.slice(0, 100)}...` : plainText}
      </div>
    );
  };

  if (loading) {
    return <div>در حال بارگذاری...</div>;
  }

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  if (posts.length === 0) {
    return <div>هیچ مقاله‌ای یافت نشد.</div>;
  }

  return (
    <div className="row g-5 g-xxl-8">
      <div className="col-xl-12">
        {posts.map((post) => {
          const isExpanded = expandedPosts.has(post.id); // تغییر نوع

          return (
            <div key={post.id} className="card mb-5 mb-xxl-8">
              <div className="card-body pb-0">
                <div className="d-flex align-items-center mb-5">
                  <div className="d-flex align-items-center flex-grow-1">
                    <div className="d-flex flex-column">
                      <a href="#" className="text-gray-800 text-hover-primary fs-6 fw-bold">
                        {post.title}
                      </a>
                      <span className="text-gray-400 fw-semibold">
                        {new Date(post.created_at).toLocaleDateString('fa-IR')}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="mb-5">
                  <div
                    className="bgi-no-repeat bgi-size-cover rounded min-h-350px mb-5"
                    style={{
                      backgroundImage: `url('${toAbsoluteUrl(post.featured_image)}')`,
                    }}
                  ></div>

                  <div className={`text-gray-800 mb-5 ${isExpanded ? 'expanded' : 'collapsed'}`}>
                    {renderContent(post.content, isExpanded)}
                  </div>

                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => toggleExpand(post.id)}
                  >
                    {isExpanded ? 'بستن' : 'مطالعه بیشتر'}
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { Blog };
