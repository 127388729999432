import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { requestPassword } from '../core/_requests'
import Swal from 'sweetalert2'

const initialValues = {
  phone: '',
}

const forgotPasswordSchema = Yup.object().shape({
  phone: Yup.string()
    .matches(/^09\d{9}$/, 'فرمت شماره موبایل اشتباه است')
    .required('ورود شماره موبایل الزامی است'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        requestPassword(values.phone)
          .then(({ data }) => {
            if (data.status) {
              Swal.fire({
                title: 'موفق!',
                text: 'رمز عبور جدید به شماره موبایل ارسال شد.',
                icon: 'success',
                confirmButtonText: 'باشه!',
              })
              setHasErrors(false)
            } else {
              Swal.fire({
                title: 'خطا!',
                text: data.message,
                icon: 'error',
                confirmButtonText: 'باشه!',
              })
              setHasErrors(true)
            }
            setSubmitting(false)
            setLoading(false)
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('شماره موبایل نا درست است.')
          })
      }, 1000)
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>رمزعبور خود را فراموش کردید؟</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-500 fw-semibold fs-6'>
          برای بازیابی رمزعبور، شماره موبایل خود را وارد نمایید.
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Title */}
      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            متاسفم، ظاهرا مشکلی در بازیابی رمزعبور به وجود آمده است! لطفا مجدد امتحان کنید.
          </div>
        </div>
      )}

      {hasErrors === false && (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>رمزعبور جدید به شماره موبایل شما پیامک شد.</div>
        </div>
      )}
      {/* end::Title */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>شماره موبایل</label>
        <input
          type='phone'
          placeholder=''
          autoComplete='off'
          {...formik.getFieldProps('phone')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.phone && formik.errors.phone },
            {
              'is-valid': formik.touched.phone && !formik.errors.phone,
            }
          )}
        />
        {formik.touched.phone && formik.errors.phone && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.phone}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' id='kt_password_reset_submit' className='btn btn-primary ms-4' disabled={loading || formik.isSubmitting}>
          {!loading ? (
            <span className="indicator-label">بازیابی رمزعبور</span>
          ) : (
            <span className="indicator-progress" style={{ display: 'block' }}>
              در حال ارسال... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            انصراف
          </button>
        </Link>{' '}
      </div>
      {/* end::Form group */}
    </form>
  )
}
