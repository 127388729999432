import { MenuItem } from './MenuItem'


export function MenuInner() {


  return (
    <>
      <MenuItem title='خانه' to='' icon='home-2' />
      <MenuItem title='بازار' to='search' icon='basket' />
      <MenuItem title='نقشه' to='map' icon='geolocation' />
      <MenuItem title='وبلاگ' to='blog' icon='feather' />
      <MenuItem title='درباه ما' to='about' icon='people' />
      <MenuItem title='تماس با ما' to='contact' icon='route' />
    </>
  )
}
