/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { lazy, FC, Suspense } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import TopBarProgress from 'react-topbar-progress-indicator'
import PageTracker from '../modules/tracker/PageTracker';

import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../modules/auth'
import { App } from '../App'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { Index } from '../pages/landing/Index'
import { Search } from '../pages/landing/Search'
import { Terms } from '../pages/landing/Terms'
import { About } from '../pages/landing/About'
import { Map } from '../pages/map/Map'
import { Blog } from '../pages/blog/Blog'
import { ContactUs } from '../pages/landing/ContactUs'
import { ProductDetail } from '../pages/shop/ProductDetail'


const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const { currentUser } = useAuth()

  const ProfilePage = lazy(() => import('../pages/shop/profile/ProfilePage'))

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <PageTracker />
      <Routes>
        <Route element={<App />}>
          <Route element={<MasterLayout />}>
            <Route index element={<Index />} />
            <Route path='search/*' element={<Search />} />
            <Route path='map' element={<Map />} />
            <Route path='blog' element={<Blog />} />
            <Route path='terms' element={<Terms />} />
            <Route path='about' element={<About />} />
            <Route path='contact' element={<ContactUs />} />

            <Route
              path='shop/:shopId/*'
              element={
                <SuspensedView>
                  <ProfilePage />
                </SuspensedView>
              }
            />
            <Route path='product/:productId' element={<ProductDetail />} />

          </Route>

          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />

          {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth/login' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { AppRoutes }
