import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { getUserByToken, login } from '../core/_requests'
import { useAuth } from '../core/Auth'
import Swal from 'sweetalert2'

const loginSchema = Yup.object().shape({
  phone: Yup.string()
    .required('وارد کردن ایمیل یا شماره موبایل الزامی است')
    .test('is-valid-identifier', 'ایمیل یا موبایل معتبر وارد کنید', (value) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      const phoneRegex = /^09\d{9}$/
      return emailRegex.test(value || '') || phoneRegex.test(value || '')
    }),
  password: Yup.string()
    .min(8, 'حداقل کارکتر مجاز رمزعبور 8 کارکتر است')
    .max(50, 'حداکثر کارکتر مجاز رمزعبور 50 کارکتر است')
    .required('ورود رمزعبور الزامی است'),
})

const initialValues = {
  phone: '',
  password: '',
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { data: auth } = await login(values.phone, values.password)
        if (auth.status) {
          saveAuth(auth)
          const { data: user } = await getUserByToken()
          if (user) {
            setCurrentUser(user)
            Swal.fire({
              title: 'ورود موفق',
              text: 'خوش آمدید.',
              icon: 'success',
              timer: 2000,
              confirmButtonText: 'بزن بریم!',
            })
          } else {
            Swal.fire({
              title: 'خطای ناشناخته',
              text: '',
              icon: 'error',
              confirmButtonText: 'بسیار خب!',
            })
          }
        } else {
          if (auth.error == 1) {
            Swal.fire({
              title: 'اطلاعات ناقص',
              text: 'تکمیل تمام فیلدها الزامی است.',
              icon: 'error',
              confirmButtonText: 'بسیار خب!',
            })
          } else if (auth.error == 2) {
            Swal.fire({
              title: 'موبایل یا ایمیل نامعتبر',
              text: 'لطفا یک شماره موبایل یا ایمیل معتبر وارد کنید.',
              icon: 'error',
              confirmButtonText: 'بسیار خب!',
            })
          } else if (auth.error == 3) {
            Swal.fire({
              title: 'کاربر یافت نشد',
              text: 'کاربری یافت نشد',
              icon: 'error',
              confirmButtonText: 'بسیار خب!',
            })
          } else if (auth.error == 4) {
            Swal.fire({
              title: 'رمز عبور اشتباه',
              text: 'رمز عبور نادرست است.',
              icon: 'error',
              confirmButtonText: 'بسیار خب!',
            })
          } else {
            Swal.fire({
              title: 'خطای ناشناخته',
              text: '',
              icon: 'error',
              confirmButtonText: 'بسیار خب!',
            })
          }
        }
        saveAuth(undefined)
        setLoading(false)
        setSubmitting(false)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('اطلاعات ورود نادرست است')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>ورود</h1>
      </div>
      {/* begin::Heading */}

      {/* begin::Login options */}

      {/* end::Login options */}

      {/* begin::Separator */}
      <div className='separator separator-content my-14'>
        <span className='w-125px text-gray-500 fw-semibold fs-7'>با موبایل یا ایمیل</span>
      </div>
      {/* end::Separator */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* {registrationSuccess && (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
            Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
            continue.
          </div>
        </div>
      )} */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>موبایل یا ایمیل</label>
        <input
          placeholder='شماره موبایل یا ایمیل خود را وارد نمایید'
          {...formik.getFieldProps('phone')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.phone && formik.errors.phone },
            {
              'is-valid': formik.touched.phone && !formik.errors.phone,
            }
          )}
          type='phone'
          name='phone'
          autoComplete='off'
        />
        {formik.touched.phone && formik.errors.phone && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.phone}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>رمز عبور</label>
        <input
          placeholder='رمز عبور خود را وارد نمایید'
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        <Link to='/auth/forgot-password' className='link-primary'>
          رمز عبور خود را فراموش کردید؟
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>ارسال</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              لطفا صبر کنید...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        حساب کاربری ندارید؟ <Link to='/auth/registration' className='link-primary'>عضویت</Link>
      </div>
    </form>
  )
}
