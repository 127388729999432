import * as Yup from 'yup'

export interface ICreateAccount {
  type: string
  businessName: string
  businessUsername: string
  state: string
  city: string
  phone: string
  address: string
  latitude: string
  longitude: string
  termsAccepted: boolean
}

const createAccountSchemas = [
  Yup.object({
    type: Yup.string().required('انتخاب نوع فعالیت فروشگاه الزامی است.'),
  }),
  Yup.object({
    businessName: Yup.string().required('ورود نام فروشگاه الزامی است.'),
    businessUsername: Yup.string().required('ورود نام کاربری فروشگاه الزامی است.').min(3, 'نام کاربری باید حداقل ۳ کاراکتر داشته باشد.').matches(/^[a-zA-Z0-9]+$/, 'نام کاربری فقط می‌تواند شامل حروف و اعداد انگلیسی باشد.'),
  }),
  Yup.object({
    state: Yup.string().required('انتخاب استان فروشگاه الزامی است.'),
    city: Yup.string().required('انتخاب شهرستان فروشگاه الزامی است.'),
    phone: Yup.string().required('ورود شماره تلفن ثابت الزامی است.').min(11, 'فرمت شماره تلفن ثابت اشتباه است.').max(11, 'فرمت شماره تلفن ثابت اشتباه است.').matches(/^0\d{10}$/, 'فرمت شماره تلفن ثابت اشتباه است'),
    address: Yup.string().required('ورود آدرس دقیق فروشگاه الزامی است.'),
    latitude: Yup.string().required('انتخاب موقعیت جغرافیایی دقیق فروشگاه الزامی است.'),
  }),
]

const inits: ICreateAccount = {
  type: '1',
  businessName: '',
  businessUsername: '',
  state: '',
  city: '',
  phone: '',
  address: '',
  latitude: '',
  longitude: '',
  termsAccepted: false,
}

export {createAccountSchemas, inits}
