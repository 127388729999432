import React, {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../helpers'
import {getModalNews} from './_requests'

const NewsModal: FC = () => {
  const [modalContent, setModalContent] = useState<string>('') // محتوای مودال
  const [showModal, setShowModal] = useState<boolean>(false) // وضعیت نمایش مودال

  useEffect(() => {
    const fetchModalData = async () => {
      try {
        const response = await getModalNews() // فراخوانی API
        if (response && response.data !== '0') {
          setModalContent(response.data) // ذخیره محتوای برگشتی
          setShowModal(true) // فعال کردن نمایش مودال
        }
      } catch (error) {
        console.error('Error fetching modal data:', error)
      }
    }

    fetchModalData()
  }, [])

  useEffect(() => {
    const modalElement = document.getElementById('kt_modal_invite_friends')
    const modalDialog = modalElement?.querySelector('.modal-dialog')

    if (showModal && modalElement) {
      // نمایش مودال با دستکاری کلاس‌ها
      modalElement.classList.add('show')
      modalElement.style.display = 'block'
      document.body.classList.add('modal-open')

      // بستن مودال هنگام کلیک روی دکمه بستن
      const closeModal = () => {
        modalElement.classList.remove('show')
        modalElement.style.display = 'none'
        document.body.classList.remove('modal-open')
        setShowModal(false)
      }

      // تنظیم رویداد برای دکمه‌های بستن
      const closeButton = modalElement.querySelector('[data-bs-dismiss="modal"]')
      closeButton?.addEventListener('click', closeModal)

      // بستن مودال هنگام کلیک خارج از آن
      const handleOutsideClick = (event: MouseEvent) => {
        // بررسی کلیک خارج از modal-dialog
        if (modalElement && modalDialog && !modalDialog.contains(event.target as Node)) {
          closeModal()
        }
      }

      // اضافه کردن رویداد برای کلیک خارج از مودال
      document.addEventListener('click', handleOutsideClick)

      // تمیز کردن
      return () => {
        closeButton?.removeEventListener('click', closeModal)
        document.removeEventListener('click', handleOutsideClick)
      }
    }
  }, [showModal])

  return (
    <div
      className="modal fade"
      id="kt_modal_invite_friends"
      aria-hidden="true"
      role="dialog"
      aria-modal="true"
      style={{display: showModal ? 'block' : 'none'}} // نمایش مودال فقط زمانی که showModal true است
    >
      <div className="modal-dialog mw-650px" style={{
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        height: '100vh', // مودال در ارتفاع صفحه قرار می‌گیرد
      }}>
        <div className="modal-content">
          <div className="modal-header pb-0 border-0 justify-content-start">
            <button
              type="button"
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <KTIcon iconName="cross" className="fs-1" />
            </button>
          </div>

          <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
            {/* محتوای برگشتی */}
            <div
              className="mb-10"
              dangerouslySetInnerHTML={{__html: modalContent}} // نمایش HTML به‌صورت امن
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {NewsModal}
