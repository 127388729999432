import React, { FC, useRef, useState, useEffect } from 'react'
import { KTIcon } from '../../../../../_metronic/helpers'
import { ErrorMessage, Field, useFormikContext } from 'formik'
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'


const Step3: FC = () => {
  const mapRef = useRef<L.Map | null>(null)

  // State برای نگهداری استان انتخاب شده و لیست شهرستان‌ها
  const [cities, setCities] = useState<string[]>([])

  // استفاده از فرمیک برای دسترسی به setFieldValue
  const { setFieldValue } = useFormikContext()

  // استیت مختصات جغرافیایی
  const [position, setPosition] = useState<[number, number]>([35.6892, 51.3890]) // مختصات پیش‌فرض (تهران)

  // لیست استان‌ها و شهرستان‌ها (به عنوان مثال)
  const states = [
    { id: 'آذربایجان شرقی', name: 'آذربایجان شرقی' },
    { id: 'آذربایجان غربی', name: 'آذربایجان غربی' },
    { id: 'اردبیل', name: 'اردبیل' },
    { id: 'اصفهان', name: 'اصفهان' },
    { id: 'ایلام', name: 'ایلام' },
    { id: 'بوشهر', name: 'بوشهر' },
    { id: 'تهران', name: 'تهران' },
    { id: 'چهارمحال و بختیاری', name: 'چهارمحال و بختیاری' },
    { id: 'خراسان جنوبی', name: 'خراسان جنوبی' },
    { id: 'خراسان رضوی', name: 'خراسان رضوی' },
    { id: 'خراسان شمالی', name: 'خراسان شمالی' },
    { id: 'خوزستان', name: 'خوزستان' },
    { id: 'زنجان', name: 'زنجان' },
    { id: 'سمنان', name: 'سمنان' },
    { id: 'سیستان و بلوچستان', name: 'سیستان و بلوچستان' },
    { id: 'فارس', name: 'فارس' },
    { id: 'قزوین', name: 'قزوین' },
    { id: 'قم', name: 'قم' },
    { id: 'کردستان', name: 'کردستان' },
    { id: 'کرمان', name: 'کرمان' },
    { id: 'کرمانشاه', name: 'کرمانشاه' },
    { id: 'کهگیلویه و بویراحمد', name: 'کهگیلویه و بویراحمد' },
    { id: 'گلستان', name: 'گلستان' },
    { id: 'گیلان', name: 'گیلان' },
    { id: 'لرستان', name: 'لرستان' },
    { id: 'مازندران', name: 'مازندران' },
    { id: 'مرکزی', name: 'مرکزی' },
    { id: 'هرمزگان', name: 'هرمزگان' },
    { id: 'همدان', name: 'همدان' },
    { id: 'یزد', name: 'یزد' },
    { id: 'البرز', name: 'البرز' },
  ]

  const citiesByState: { [key: string]: string[] } = {
    'آذربایجان شرقی': ['آذرشهر', 'اسکو', 'اهر', 'بستان‌آباد', 'بناب', 'تبریز', 'جلفا', 'چاراویماق', 'سراب', 'شبستر', 'مراغه'],
    'آذربایجان غربی': ['ارومیه', 'اشنویه', 'بوکان', 'پیرانشهر', 'تکاب', 'چالدران', 'خوی', 'سردشت', 'سلماس', 'شاهین‌دژ', 'ماکو', 'مهاباد', 'میاندوآب', 'نقده'],
    'اردبیل': ['اردبیل', 'بیله‌سوار', 'پارس‌آباد', 'خلخال', 'کوثر', 'گِرمی', 'مِشگین‌شهر', 'نَمین', 'نیر'],
    'اصفهان': ['آران و بیدگل', 'اردستان', 'اصفهان', 'برخوار و میمه', 'تیران و کرون', 'چادگان', 'خمینی‌شهر', 'خوانسار', 'سمیرم', 'شهرضا', 'سمیرم سفلی', 'فریدن', 'فریدون‌شهر', 'فلاورجان', 'کاشان', 'گلپایگان', 'لنجان', 'مبارکه', 'نائین', 'نجف‌آباد', 'نطنز'],
    'ایلام': ['آبدانان', 'ایلام', 'ایوان', 'دره‌شهر', 'دهلران', 'شیروان و چرداول', 'مهران'],
    'بوشهر': ['بوشهر', 'تنگستان', 'جم', 'دشتستان', 'دشتی', 'دیر', 'دیلم', 'کنگان', 'گناوه'],
    'تهران': ['اسلام‌شهر', 'پاکدشت', 'تهران', 'دماوند', 'رباط‌کریم', 'ری', 'ساوجبلاغ', 'شمیرانات', 'شهریار', 'فیروزکوه', 'ورامین'],
    'چهارمحال و بختیاری': ['اردل', 'بروجن', 'شهرکرد', 'فارسان', 'کوهرنگ', 'لردگان'],
    'خراسان جنوبی': ['بیرجند', 'درمیان', 'سرایان', 'سربیشه', 'فردوس', 'قائنات', 'نهبندان'],
    'خراسان رضوی': ['بردسکن', 'تایباد', 'تربت جام', 'تربت حیدریه', 'چناران', 'خلیل‌آباد', 'خواف', 'درگز', 'رشتخوار', 'سبزوار', 'سرخس', 'فریمان', 'قوچان', 'کاشمر', 'کلات', 'گناباد', 'مشهد', 'مه ولات', 'نیشابور'],
    'خراسان شمالی': ['اسفراین', 'بجنورد', 'جاجرم', 'شیروان', 'فاروج', 'مانه و سملقان'],
    'خوزستان': ['آبادان', 'امیدیه', 'اندیمشک', 'اهواز', 'ایذه', 'باغ‌ملک', 'بندر ماهشهر', 'بهبهان', 'خرمشهر', 'دزفول', 'دشت آزادگان', 'رامشیر', 'رامهرمز', 'شادگان', 'شوش', 'شوشتر'],
    'زنجان': ['ابهر', 'ایجرود', 'خدابنده', 'خرمدره', 'زنجان', 'طارم', 'ماه‌نشان'],
    'سمنان': ['دامغان', 'سمنان', 'شاهرود', 'گرمسار', 'مهدی‌شهر'],
    'سیستان و بلوچستان': ['ایرانشهر', 'چابهار', 'خاش', 'دلگان', 'زابل', 'زاهدان', 'زهک', 'سراوان', 'سرباز', 'کنارک', 'نیک‌شهر'],
    'فارس': ['آباده', 'ارسنجان', 'استهبان', 'اقلید', 'بوانات', 'پاسارگاد', 'جهرم', 'خرم‌بید', 'خنج', 'داراب', 'زرین‌دشت', 'سپیدان', 'شیراز', 'فراشبند', 'فسا', 'فیروزآباد', 'قیر و کارزین', 'کازرون', 'لارستان', 'لامِرد', 'مرودشت', 'ممسنی', 'مهر', 'نی‌ریز'],
    'قزوین': ['آبیک', 'البرز', 'بوئین‌زهرا', 'تاکستان', 'قزوین'],
    'قم': ['قم'],
    'کردستان': ['بانه', 'بیجار', 'دیواندره', 'سروآباد', 'سقز', 'سنندج', 'قروه', 'کامیاران', 'مریوان'],
    'کرمان': ['بافت', 'بردسیر', 'بم', 'جیرفت', 'راور', 'رفسنجان', 'رودبار جنوب', 'زرند', 'سیرجان', 'شهر بابک', 'عنبرآباد', 'قلعه گنج', 'کرمان', 'کوهبنان', 'کهنوج', 'منوجان'],
    'کرمانشاه': ['اسلام‌آباد غرب', 'پاوه', 'ثلاث باباجانی', 'جوانرود', 'دالاهو', 'روانسر', 'سرپل ذهاب', 'سنقر', 'صحنه', 'قصر شیرین', 'کرمانشاه', 'کنگاور', 'گیلان غرب', 'هرسین'],
    'کهگیلویه و بویراحمد': ['بویراحمد', 'بهمئی', 'دنا', 'کهگیلویه', 'گچساران'],
    'گلستان': ['آزادشهر', 'آق‌قلا', 'بندر گز', 'ترکمن', 'رامیان', 'علی‌آباد', 'کردکوی', 'کلاله', 'گرگان', 'گنبد کاووس', 'مراوه‌تپه', 'مینودشت'],
    'گیلان': ['آستارا', 'آستانه اشرفیه', 'اَملَش', 'بندر انزلی', 'رشت', 'رضوانشهر', 'رودبار', 'رودسر', 'سیاهکل', 'شَفت', 'صومعه‌سرا', 'طوالش', 'فومَن', 'لاهیجان', 'لنگرود', 'ماسال'],
    'لرستان': ['ازنا', 'الیگودرز', 'بروجرد', 'پل‌دختر', 'خرم‌آباد', 'دورود', 'دلفان', 'سلسله ,کوهدشت'],
    'مازندران': ['آمل', 'بابل', 'بابلسر', 'بهشهر', 'تنکابن', 'جویبار', 'چالوس', 'رامسر', 'ساری', 'سوادکوه', 'قائم‌شهر', 'گلوگاه', 'محمودآباد', 'نکا', 'نور', 'نوشهر'],
    'مرکزی': ['آشتیان', 'اراک', 'تفرش', 'خمین', 'دلیجان', 'زرندیه', 'ساوه', 'شازند', 'کمیجان', 'محلات'],
    'هرمزگان': ['ابوموسی', 'بستک', 'بندر عباس', 'بندر لنگه', 'جاسک', 'حاجی‌آباد', 'شهرستان خمیر', 'رودان', 'قشم', 'گاوبندی', 'میناب'],
    'همدان': ['اسدآباد', 'بهار', 'تویسرکان', 'رزن', 'کبودرآهنگ', 'ملایر', 'نهاوند', 'همدان'],
    'یزد': ['اسدآباد', 'بهار', 'تویسرکان', 'رزن', 'کبودرآهنگ', 'ملایر', 'نهاوند', 'همدان'],
    'البرز': ['کرج', 'نظرآباد', 'فردیس', 'اشتهارد', 'هشتگرد', 'طالقان'],
  }

  // این تابع زمانی که استان تغییر می‌کند، لیست شهرستان‌ها را تنظیم می‌کند
  const handleStateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedState = e.target.value
    setFieldValue('state', selectedState) // استان را به فرمیک تنظیم کنید
    setFieldValue('city', '') // دی‌سلکت کردن شهرستان
    setCities(citiesByState[selectedState] || []) // لیست شهرستان‌ها را بروزرسانی کنید
  }



  
  // کامپوننتی که به نقشه متصل است و مختصات را به فرمیک می‌دهد
  const LocationMarker = () => {
    const map = useMapEvents({
      click(e: L.LeafletMouseEvent) {
        const { lat, lng } = e.latlng
        setPosition([lat, lng])
        setFieldValue('latitude', lat)
        setFieldValue('longitude', lng)
        map.setView([lat, lng], map.getZoom())
      },
    })
  
    const customIcon = new L.Icon({
      iconUrl: 'https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon.png',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [0, -41],
    })
  
    return (
      <Marker position={position} icon={customIcon}>
        <Popup>
          مختصات دقیق فروشگاه شما<br />
          {position[0]} , {position[1]}
        </Popup>
      </Marker>
    )
  }
  




  return (
    <div className='w-100'>
      <div className='row mb-10'>
        <div className='col-md-6 fv-row'>
          <label className='required fs-6 fw-bold form-label mb-2'>استان</label>
          <Field
            as='select'
            name='state'
            className='form-select form-select-solid'
            onChange={handleStateChange}
          >
            <option value=''>استان را انتخاب کنید...</option>
            {states.map((state) => (
              <option key={state.id} value={state.id}>
                {state.name}
              </option>
            ))}
          </Field>
          <div className='text-danger mt-2'>
            <ErrorMessage name='state' />
          </div>
        </div>
        <div className='col-md-6 fv-row'>
          <label className='required fs-6 fw-bold form-label mb-2'>شهرستان</label>
          <Field as='select' name='city' className='form-select form-select-solid'>
            <option value=''>شهرستان را انتخاب کنید...</option>
            {cities.map((city, index) => (
              <option key={index} value={city}>
                {city}
              </option>
            ))}
          </Field>
          <div className='text-danger mt-2'>
            <ErrorMessage name='city' />
          </div>
        </div>

        <div className='col-md-12 fv-row mt-2'>
          <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
            <span className='required'>تلفن ثابت</span>
          </label>

          <div className='position-relative'>
            <Field
              type='text'
              className='form-control form-control-solid'
              placeholder='02132165498'
              name='phone'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='phone' />
            </div>

            <div className='position-absolute translate-middle-y top-50 start-0 ms-3'>
              <KTIcon iconName='ki-solid ki-delivery-24' className='fs-2hx' />
            </div>
          </div>
        </div>

        <div className='fv-row mt-2'>
          <label className='form-label required'>آدرس با کد پستی</label>

          <Field
            as='textarea'
            name='address'
            className='form-control form-control-lg form-control-solid'
            rows={3}
          ></Field>
          <div className='text-danger mt-2'>
            <ErrorMessage name='address' />
          </div>
        </div>

        <div className='col-md-12 fv-row mt-2'>
          <label className='required fs-6 fw-bold form-label mb-2'>مختصات جغرافیایی فروشگاه</label>
          <div className='form-control form-control-solid'>
            <MapContainer
              center={position}
              zoom={11}
              style={{ height: '300px', width: '100%' }}
              ref={(map) => {
                mapRef.current = map;
              }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <LocationMarker />
            </MapContainer>
          </div>
          <div className='text-danger mt-2'>
            <ErrorMessage name='latitude' />
            <ErrorMessage name='longitude' />
          </div>
        </div>
      </div>


    </div >
  )
}

export { Step3 }
