import { FC } from 'react'


const Terms: FC = () => {
  return (
    <>
      {/* begin::Row*/}
      <div className='row g-5 g-xl-10 mb-xl-10'>
        <div className='col-12'>
          <h1 className='text-end'>سیاست‌ها و مقررات دیجی نمایشگاه</h1>
          <h2>ماده ۱- نحوه استفاده از سایت</h2>
          <ol>
            <li>به منظور مشاهده‌ی آگهی‌ها و استفاده از قابلیت‌های سایت، شما می‌بایست شماره تلفن همراه فعال خود را که کد تأیید به آن ارسال می‌شود، ثبت نموده و حساب کاربری خود را ایجاد کنید.</li>
            <li>به منظور ثبت آگهی شما می‌بایست ابتدا با استفاده از شماره تلفن همراهی که به نامتان ثبت شده و با کد ملی شما انطباق دارد، حساب کاربری ایجاد کنید.</li>
            <li>کاربرانی که قصد استفاده‌ی حرفه‌ای از سایت را دارند می‌توانند در بخش «ویژه_حرفه‌ای» کسب و کار خود را تعریف کنند و از امکانات حرفه‌ای سایت برای مدیریت راحت‌تر و بهتر کسب و کار خود استفاده کنند. برای استفاده از امکانات دیجی نمایشگاه حرفه‌ای نیازی به احراز هویت مجزا نیست و ثبت نام در دیجی نمایشگاه و انطباق شماره تلفن با کد ملی کفایت می‌کند.</li>
            <li>برای هرگونه فعالیت در سایت، می‌بایست شرایط زیر را رعایت کنید:
              <ul>
                <li>الف) داشتن ۱۸ سال تمام. فعالیت افراد زیر ۱۸ سال می‌بایست با نظارت والدین صورت بگیرد.</li>
                <li>ب) انطباق فعالیت شما با قوانین، آیین‌نامه‌ها، بخشنامه‌ها، مصوبات، دستورالعمل‌های جاری جمهوری اسلامی ایران، دستورات مقامات قضایی، عرف و نظم عمومی.</li>
                <li>پ) انطباق فعالیت شما و آگهی‌هایتان با شرایط و مقررات دیجی نمایشگاه.</li>
                <li>ت) استفاده از زبان فارسی برای انتشار هر گونه محتوا.</li>
              </ul>
            </li>
          </ol>

          <h2>ماده ۳- سیاست‌های سایت و اپلیکیشن سایت</h2>
          <p>سیاست‌های کلی ما برای ارائه‌ی خدمات به شما از این قرار است:</p>
          <h3>۱) سیاست‌های انتشار آگهی</h3>
          <ul>
            <li>عدم انتشار آگهی تکراری و انتشار آگهی در دسته‌بندی نادرست</li>
            <li>عدم انتشار بیش از یک کالا یا خدمت در هر آگهی</li>
            <li>عدم انتشار آگهی درخواست کالا یا خدمت</li>
            <li>عدم انتشار هرگونه آگهی که در فهرست موارد ممنوعه قرار دارد و یا به تشخیص نهادهای تخصصی موقتاً دارای محدودیت و ممنوعیت باشد</li>
            <li>عدم انتشار هرگونه آگهی که شامل عباراتی غیرمرتبط با موضوع آن بوده و یا دارای مضمون مستهجن، مبتذل، نامتعارف، نادرست، مبهم، گمراه‌کننده و یا برخلاف قوانین کشور باشد</li>
            <li>عدم انتشار هرگونه آگهی که شامل خبرپراکنی با موضوع سیاسی، اجتماعی، مذهبی، و یا توهین، نشر اکاذیب، افترا، عقاید شخصی، دعوت به تظاهرات و کمپین‌ها و مغایر با ارزش‌های دیجی نمایشگاه باشد</li>
          </ul>

          <h3>۲) سیاست‌های انتشار عکس</h3>
          <p>شما در برابر عکس‌های آگهی‌های خود شخصاً مسئولیت دارید و باید با رعایت قوانین و مقررات جاری کشور و همچنین شرایط و مقررات سایت، ضمن رعایت و حفظ حقوق اشخاص ثالث، برای انتشار تصویر اقدام کنید.</p>
          <ul>
            <li>تصویر بیش از حد کوچک یا بی‌کیفیت</li>
            <li>تصویر نامرتبط با کالا یا خدمت ارائه‌شده</li>
            <li>تصویر آگهی‌های دیگر در سایت</li>
            <li>اسکرین‌شات و عکس‌های دانلودی و به طور کل هر عکسی که مستقیماً از خود کالا گرفته نشده باشد</li>
          </ul>

          <h3>۳) سیاست‌های چت در سایت</h3>
          <p>چت دیجی نمایشگاه یک فضای ارتباطی بین آگهی‌گذار و آگهی‌بیننده است که با هدف تسهیل ارتباط در راستای انجام معامله ارائه شده است.</p>
          <ul>
            <li>هدف از ایجاد چت سایت تسهیل ارتباط کاربران به منظور خرید و فروش کالا یا ارائه‌ی خدمات درج‌شده در آگهی‌ها است.</li>
            <li>استفاده از چت سایت برای مقاصد دیگر از جمله ارتباطات شخصی، سیاسی، اجتماعی و... ممنوع است.</li>
          </ul>

          <h3>۴) سیاست‌های تماس ناشناس سایت</h3>
          <p>تماس ناشناس سایت یک قابلیت برای آگهی‌گذار و آگهی‌بیننده است که با هدف افزایش امنیت کاربران و جلوگیری از مزاحمت ارائه شده‌ است.</p>

          <h3>۵) سیاست‌های مرتبط با کلاهبرداری و مزاحمت</h3>
          <p>شما باید در هنگام معامله هویت طرف قرارداد را احراز نموده و از امن بودن و صحت شرایط قرارداد مطمئن شوید. سایت مسئولیتی در قبال صحت‌سنجی معاملات ندارد.</p>

          <h3>۶) سیاست‌های مربوط به هزینه‌ها و خدمات</h3>
          <p>در برخی دسته‌بندی‌ها ارسال آگهی برای کاربران، تا سقف مشخصی در ماه رایگان است. بدیهی است برای ارسال آگهی بیش از تعداد مشخص‌شده، نیاز به پرداخت هزینه است.</p>

          <h2>ماده ۴- حقوق و مسئولیت‌های سایت</h2>
          <ol>
            <li>سایت و اپلیکیشن حسب تأییدیه‌ی صادره از سوی سازمان نظام صنفی رایانه‌ای، فضایی را در سامانه‌ی رایانه‌ای خود در اختیار کاربران قرار می‌دهد تا آگهی‌های خود را منتشر کنند.</li>
            <li>شما با فعالیت در سایت و اپلیکیشن، موافقت خود را با عدم مسئولیت سایت در قبال اقدامات سایر کاربران اعلام کرده‌اید.</li>
            <li>تمام محتویات آگهی‌ها اعم از موضوع، توضیحات، لینک و... توسط کاربران ارائه می‌شود.</li>
          </ol>

          <h2>ماده ۵- حقوق و مسئولیت‌های کاربر</h2>
          <ul>
            <li>شما می‌پذیرید در صورت بارگذاری آگهی خلاف سیاست‌های سایت و یا هرگونه عملکرد مخرب، عمل شما منجر به خدشه‌دار شدن اعتبار سایت شده و در نتیجه حق پیگیری برای سایت محفوظ است.</li>
            <li>شما می‌پذیرید در صورتی که اطلاعات سایت را به اشخاص غیر از سایت و اپلیکیشن دیوار بدهید، خود مسئول این عمل خواهید بود.</li>
            <li>شما می‌پذیرید که از سایت صرفاً برای مقاصد تجاری و اقتصادی استفاده نمایید.</li>
          </ul>

          <h2>ماده ۶- ضمانت‌اجرای تخلف از تعهدات</h2>
          <ul>
            <li>این حق برای سایت محفوظ است که هرگاه لازم بداند آگهی‌های مغایر با شرایط و مقررات را حذف کند.</li>
            <li>سایت می‌تواند با تشخیص خود هرگاه لازم بداند کاربر متخلف را موقتاً یا دائماً مسدود کند.</li>
          </ul>

          <h2>ماده ۷- مالکیت فکری</h2>
          <p>کلیه حقوق مالکیت فکری سایت شامل نرم‌افزار، نام تجاری دیجی نمایشگاه و سایر موارد ثبت شده است.</p>

          <h2>ماده ۸- حفاظت از حریم خصوصی</h2>
          <ul>
            <li>اصل حفاظت از حریم خصوصی قواعد رفتاری ما در حوزه‌ی استفاده، جمع‌آوری و ذخیره‌سازی اطلاعات شخصی کاربران را تبیین می‌کند.</li>
            <li>با استفاده از سایت و اپلیکیشن، شما رضایت خود را از جمع‌آوری و استفاده از اطلاعات شخصی اعلام می‌کنید.</li>
          </ul>
        </div>

      </div>
      {/* end::Row */}
    </>
  )
}

export { Terms }
