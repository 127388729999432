import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const GET_PRICE_URL = `${API_URL}/get_upgrade_price`
export const POST_UPGRADE_URL = `${API_URL}/upgrade`



export function getUpgradePrice() {
  return axios.get(GET_PRICE_URL, {});
}

export function upgrade(
  plan: string,
  period: string,
) {
  return axios.post(POST_UPGRADE_URL, {
    plan: plan,
    billing_cycle: period,
  })
}
