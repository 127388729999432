import React from 'react'
import { Link } from 'react-router-dom'
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { ProductModel } from '../Models'

interface ProductsProps {
    products: ProductModel[]
}

const Products: React.FC<ProductsProps> = ({ products }) => {
  return (
    <div className="row g-5 g-xxl-8">
      {products.map((product) => (
        <div key={product.id} className="col-md-3">
          <div className="card mb-5 mb-xxl-8">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2">
                <div className="d-flex align-items-center flex-grow-1">
                  <div className="d-flex flex-column">
                    <Link
                      to={`/product/${product.id}`}
                      className="text-gray-800 text-hover-primary fs-6 fw-bold"
                    >
                      {product.title}
                    </Link>
                    <span className="text-gray-400 fw-semibold">
                      {new Date(product.created_at).toLocaleDateString('fa-IR')}
                    </span>
                  </div>
                </div>

                <div className="my-0">
                  
                </div>
              </div>

              <Link to={`/product/${product.id}`}>
                <div
                  className="bgi-no-repeat bgi-size-cover rounded min-h-200px mb-5"
                  style={{
                    backgroundImage: `url('${toAbsoluteUrl(product.featured_image)}')`,
                  }}
                ></div>
              </Link>

              <div className="d-flex justify-content-between">
                <span className="text-gray-400 fw-semibold d-flex align-items-center">
                  <KTIcon iconName="category" className="ms-2" /> <Link to={`/search?c=${product.category.id}`}>{product.category.name}</Link>
                </span>
                <span className="text-gray-400 fw-semibold d-flex align-items-center">
                  <KTIcon iconName="eye" className="ms-2" /> {product.views}
                </span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export { Products };
