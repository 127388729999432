import React, { FC } from 'react'
import { KTIcon } from '../../../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { Field } from 'formik'

const Step4: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-8 pb-lg-10'>
        <h2 className='fw-bolder text-dark'>به همین سادگی!</h2>
      </div>

      <div className='mb-0'>
        <div className='fs-6 text-gray-600 mb-5'>
          با زدن روی دکمه ثبت فروشگاه شما به همین سادگی ساخته شده و می‌توانید کار خود را آغاز نمایید.
        </div>

        <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
          <KTIcon iconName='information-5' className='fs-2tx text-warning me-4' />
          <div className='d-flex flex-stack flex-grow-1'>
            <div className='fw-bold'>
              <h4 className='text-gray-800 fw-bolder'>نکته مهم!</h4>
              <div className='fs-6 text-gray-600'>
                لطفا قبل از ثبت، از صحت اطلاعات وارد شده اطمینان حاصل نمایید.
              </div>
            </div>
          </div>
        </div>

        {/* اضافه کردن چک‌باکس پذیرش قوانین */}
        <div className='form-group mt-4'>
          <Field
            name='termsAccepted'
            type='checkbox'
            className='form-check-input'
            id='termsAccepted'
          />
          <label className='form-check-label pe-2' htmlFor='termsAccepted'>
            من قوانین و شرایط <Link to='/terms' target='_blank'>استفاده از دیجی نمایشگاه</Link> را مطالعه کرده و می‌پذیرم.
          </label>
        </div>
      </div>
    </div>
  )
}

export { Step4 }
