import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getProductById } from './_requests'
import { ProductModel } from './ShopModels'
import Loader from '../../../_metronic/helpers/Loader'
import { KTIcon } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'

const ProductDetail = () => {
  const { productId } = useParams<{ productId: string }>()
  const [product, setProduct] = useState<ProductModel | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (productId) {
      getProductById(productId)
        .then((response) => {
          setProduct(response.data)
          setLoading(false)
        })
        .catch((error) => {
          console.error('Error fetching product data:', error)
          setLoading(false)
        })
    }
  }, [productId])

  if (loading) {
    return <Loader />
  }

  if (!product) {
    return <div>محصولی یافت نشد.</div>
  }

  return (
    <div className='row'>
      <div className='col-lg-4'>
        <div className='mb-xl-8'>
          <div className='p-0'>
            {/* begin::Header */}
            <div className='px-9 pt-7 card-rounded h-275px w-100 bg-danger'>
              {/* begin::Heading */}
              <div className='d-flex flex-stack'>
                <h3 className='m-0 text-white fw-bold fs-3'>{product.shop.name}</h3>
                <div className='text-white ms-1'>
                  {product.shop.username}@
                </div>
              </div>
              {/* end::Heading */}
              {/* begin::Balance */}
              <div className='d-flex text-center flex-column text-white pt-3'>
                <span><img
                  src={product.shop.avatar || '/media/avatars/blank.png'}
                  className='img-fluid mb-4 rounded-circle'
                  style={{ maxWidth: '100px' }}
                /></span>
              </div>
              {/* end::Balance */}
            </div>
            {/* end::Header */}
            <div
              className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-body'
              style={{ marginTop: '-100px' }}
            >
              {/* begin::Item */}
              <div className='d-flex align-items-center mb-6'>
                {/* begin::Description */}
                <div className='d-flex align-items-center flex-wrap w-100'>
                  {/* begin::Title */}
                  <div className='mb-1 pe-3 flex-grow-1'>
                    <span className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                      تاریخ عضویت
                    </span>
                  </div>
                  {/* end::Title */}
                  {/* begin::Label */}
                  <div className='d-flex align-items-center'>
                    <div className='fs-5 text-gray-700 pe-1'>{new Date(product.created_at).toLocaleDateString('fa-IR')}</div>
                  </div>
                  {/* end::Label */}
                </div>
                {/* end::Description */}
              </div>
              {/* end::Item */}
              {/* begin::Item */}
              <div className='d-flex align-items-center mb-6'>
                {/* begin::Description */}
                <div className='d-flex align-items-center flex-wrap w-100'>
                  {/* begin::Title */}
                  <div className='mb-1 pe-3 flex-grow-1'>
                    <span className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                      تلفن تماس
                    </span>
                  </div>
                  {/* end::Title */}
                  {/* begin::Label */}
                  <div className='d-flex align-items-center'>
                    <div className='fs-5 text-gray-700 pe-1'>{product.shop.phone}</div>
                  </div>
                  {/* end::Label */}
                </div>
                {/* end::Description */}
              </div>
              {/* end::Item */}
              {/* begin::Item */}
              <div className='d-flex align-items-center mb-6'>
                {/* begin::Description */}
                <div className='d-flex align-items-center flex-wrap w-100'>
                  {/* begin::Title */}
                  <div className='mb-1 pe-3 flex-grow-1'>
                    <span className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                      آدرس
                    </span>
                  </div>
                  {/* end::Title */}
                  {/* begin::Label */}
                  <div className='d-flex align-items-center'>
                    <div className='fs-5 text-gray-700 pe-1'>{product.shop.state}, {product.shop.city}</div>
                  </div>
                  {/* end::Label */}
                </div>
                {/* end::Description */}
              </div>
              {/* end::Item */}
            </div>
          </div>
        </div>
        <a href={`tel:${product.shop.phone}`} className='btn btn-primary w-100 mb-2'>
          تماس با فروشگاه
        </a>
        <a href={`/shop/${product.shop.username}`} className='btn btn-outline-secondary w-100'>
          مشاهده فروشگاه
        </a>
      </div>

      <div className='col-lg-8'>
        <div className='card'>
          <div className='card-body'>
            <h1>{product.title}</h1>
            <span className="text-gray-400 fw-semibold">
              <KTIcon iconName='time' className='ms-1' /> {new Date(product.created_at).toLocaleDateString('fa-IR')}
              <KTIcon iconName='category' className='me-5 ms-1' /> <Link to={`/search?c=${product.category.id}`}>{product.category.name}</Link>
              <KTIcon iconName='eye' className='me-5 ms-1' /> {product.views}
            </span>
            <img
              src={product.featured_image}
              alt={product.title}
              className='img-fluid mb-4 mt-2 card-rounded'
              style={{ maxHeight: '300px', objectFit: 'cover', width: '100%' }}
            />
            <div dangerouslySetInnerHTML={{ __html: product.description as string }} />
          </div>
        </div>
      </div>
    </div>
  )
}

export { ProductDetail }
