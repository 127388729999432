import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { addVisit } from './_requests'

const PageTracker = () => {
  const location = useLocation()

  useEffect(() => {
    const sendPageView = async () => {
        await addVisit(window.location.href)
    }

    sendPageView();
  }, [location.pathname])

  return null;
}

export default PageTracker
