import React, { FC } from 'react'
import { KTIcon } from '../../../helpers'
import { useAuth } from '../../../../app/modules/auth'

const InviteUsers: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { currentUser } = useAuth()

  // تابع کپی کردن به کلیپ‌بورد
  const copyToClipboard = () => {
    const link = `https://diginamayeshgah.com/auth/registration?ref=${currentUser?.id}`
    navigator.clipboard.writeText(link).then(() => {
      alert('لینک دعوت کپی شد!')
    }).catch(err => {
      console.error('خطا در کپی:', err)
    })
  }

  return (
    <div className='modal fade show d-block' id='kt_modal_invite_friends' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div onClick={onClose} className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>دعوت از دوستان</h1>
              <div className='text-muted fw-bold fs-5'>
                با معرفی دوستانتان هر بار که خرید می‌کنند ۳۰% به شما تعلق می‌گیرد.
              </div>
            </div>

            <div className='btn btn-light-primary fw-bolder w-100 mb-8'>
              لینک اختصاصی دعوت شما
            </div>

            <div className='separator d-flex flex-center mb-8'></div>

            {/* لینک کلیک‌پذیر */}
            <code
              className='d-block text-center cursor-pointer text-primary'
              onClick={copyToClipboard}
              style={{ cursor: 'pointer' }}
            >
              https://diginamayeshgah.com/?ref={currentUser?.id}
            </code>

            <div className='d-flex flex-stack mt-10'>
              <div className='fw-bold w-100 d-flex justify-content-between'>
                <div className='fs-7'>
                  تعداد دوستان شما: {currentUser?.ref_num} نفر
                </div>
                <div className='fs-7'>
                  مجموع درآمد: {currentUser?.ref_income} تومان
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { InviteUsers }
