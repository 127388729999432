import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import { getFollowedProducts } from '../landing/_requests'
import { ProductModel } from '../landing/Models'
import { Products } from '../landing/components/productCard'
import { useAuth } from '../../../app/modules/auth'
import { UpgradePlan } from '../../../_metronic/partials/modals/upgrade-plan/UpgradePlan'
import { InviteUsers } from '../../../_metronic/partials/modals/invite-users/InviteUsers'

const DashboardWrapper: FC = () => {
  const [followedProducts, setFollowedProducts] = useState<ProductModel[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const { currentUser } = useAuth()
  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false)
  const [showInviteModal, setShowInviteModal] = useState<boolean>(false)

  useEffect(() => {
    const fetchFollowedProducts = async () => {
      setLoading(true)
      setError(null)

      try {
        // Directly sending parameters for fetching the most popular products
        const response = await getFollowedProducts()
        setFollowedProducts(response.data)
      } catch (err) {
        setError('خطا در دریافت محصولات دنبال شده')
      } finally {
        setLoading(false)
      }
    }

    fetchFollowedProducts()
  }, [])

  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10'>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-10'>
          <div className={`card card-flush h-md-100`}>
            <div
              className='card-body d-flex flex-column justify-content-between mt-9 bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0'
              style={{
                backgroundPosition: '100% 50%',
                backgroundImage: `url('${toAbsoluteUrl('/media/stock/900x600/42.png')}')`,
              }}
            >
              <div>
                <div className='fs-1 fw-bold text-gray-800 text-center mb-13'>
                  <span className='me-2'>
                    به دیجی نمایشگاه؛ شبکه اجتماعی صنوف
                    <br />
                    <span className='position-relative d-inline-block text-success'>
                      خوش آمدید!
                      <span className='position-absolute opacity-15 bottom-0 start-0 border-4 border-danger border-bottom w-100'></span>
                    </span>
                  </span>
                </div>

                <div className='text-center'>
                  {currentUser?.shopUsername ? (
                    <Link to={`/shop/${currentUser.shopUsername}`} className='btn fw-bold btn-secondary me-5'>
                      فروشگاه من
                    </Link>
                  ) : (
                    <Link to='/NewShop' className='btn fw-bold btn-primary'>
                      ساخت فروشگاه
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-10'>
          <div className='card card-flush'>
            <div className='card-header pt-5 pb-5'>
              <div className='card-title d-flex flex-column'>
                <div className='d-flex align-items-center'>
                  <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>{currentUser?.wallet}</span>
                  <span className='fs-4 fw-semibold text-gray-400 me-1 align-self-start'>تومان</span>
                  <a href="#" className='badge badge-light-success fs-base'>افزایش موجودی</a>
                </div>
                <span className='text-gray-400 pt-1 fw-semibold fs-6'>موجودی کیف پول</span>
              </div>
            </div>
          </div>
          <div className='card card-flush bg-dark mt-5'>
            <div className='card-header pt-5 pb-5'>
              <div className='card-title d-flex flex-column m-auto'>
                {currentUser?.plan ? (
                  // نمایش جزئیات پلن کاربر در صورت وجود عضویت
                  <>
                    <div className='d-flex flex-column align-items-center text-white'>
                      <span className='fs-4 fw-bold'>
                        شما دارای عضویت {currentUser.plan} هستید.
                      </span>
                      <span className='fs-6 text-success mt-3'>
                        تاریخ انقضا: {currentUser.plan_expiry ? new Date(currentUser.plan_expiry).toLocaleDateString('fa-IR') : 'نامشخص'}
                      </span>
                    </div>
                  </>
                ) : (
                  // نمایش متن و دکمه برای خرید عضویت در صورت نداشتن پلن
                  <>
                    <div className='d-flex align-items-center text-white'>
                      با ارتقا عضویت حساب، دیجی نمایشگاه را بدون محدودیت تجربه کنید.
                    </div>
                    <div className='text-center mt-5 m-auto'>
                      <button
                        onClick={() => setShowUpgradeModal(true)}
                        className='btn fw-bold btn-warning'
                      >
                        خرید عضویت ویژه
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end::Row */}

      {/* باکس دعوت از دوستان */}
      <div className='card card-flush bg-light-success mb-5 mb-xl-10'>
        <div className='card-header pt-5 pb-5'>
          <div className='card-title d-flex flex-column m-auto text-center'>
            <div className='fs-4 fw-bold text-dark'>
              کسب درآمد با دعوت از دوستان
            </div>
            <div className='text-gray-600 mt-2'>
              دوستان خود را دعوت کنید و ۳۰% از خرید آنها پاداش بگیرید.
            </div>
            <button
              onClick={() => setShowInviteModal(true)}
              className='btn fw-bold btn-primary mt-4'
            >
              دعوت از دوستان
            </button>
          </div>
        </div>
      </div>
      {/* begin::Row*/}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-12'>
          {loading ? (
            <div>در حال بارگذاری...</div>
          ) : error ? (
            <div className="alert alert-danger">{error}</div>
          ) : (
            <>
              {followedProducts.length != 0 && <h3 className='text-end mb-10'>محصولات فروشگاه‌های دنبال شده</h3>}
              <Products products={followedProducts} />
            </>
          )}
        </div>
      </div>
      {/* end::Row */}

      {/* Conditionally render UpgradePlan modal */}
      {showUpgradeModal && <UpgradePlan onClose={() => setShowUpgradeModal(false)} />}

      {/* Conditionally render InviteUsers modal */}
      {showInviteModal && <InviteUsers onClose={() => setShowInviteModal(false)} />}
    </>
  )
}

export { DashboardWrapper }
