import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { useLayout } from '../../core'
import { KTIcon } from '../../../helpers'
import { Footer } from './Footer'
import { Link, useLocation } from 'react-router-dom'

const FooterWrapper = () => {
  const { config } = useLayout()
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false)
  const location = useLocation()

  const menuItems = [
    { path: '/dashboard', icon: 'abstract-30', label: 'پیشخوان' },
    { path: '/search', icon: 'basket', label: 'بازار' },
    { path: '/NewShop', icon: 'abstract-10', label: 'فروشگاه' },
    { path: '/map', icon: 'geolocation', label: 'نقشه' },
    { path: '/setting', icon: 'setting-2', label: 'تنظیمات' },
  ]

  useEffect(() => {
    // بررسی اندازه صفحه
    const handleResize = () => {
      setIsMobileOrTablet(window.innerWidth <= 1024) // موبایل و تبلت
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  if (!config.app?.footer?.display) {
    return null
  }

  return (
    <div className='app-footer' id='kt_app_footer'>
      {config.app.footer.containerClass ? (
        <div className={clsx('app-container', config.app.footer.containerClass)}>
          <Footer />
        </div>
      ) : (
        <Footer />
      )}
      {isMobileOrTablet && (
        <div className="footer-container">
          <div className="bar">
            {menuItems.map((item, index) => (
              <Link
                key={index}
                to={item.path}
                className={clsx('bar-item text-center', {
                  active: location.pathname === item.path,
                })}
              >
                <div className="bar-fluid"></div>
                <div className="drop"></div>
                <KTIcon
                  iconName={`${item.icon}`}
                  className=''
                />
                <span className="bar-label">{item.label}</span>
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export { FooterWrapper }
