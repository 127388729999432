import {useEffect, useRef} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../helpers'
import {createChart, CrosshairMode} from 'lightweight-charts'
import axios from 'axios'
import './CreateChartModal.scss'

// تعریف Generic برای ساختار candle
type Candle = {
  time: string
  open: number
  high: number
  low: number
  close: number
  volume: number
}

// تابع محاسبه مقدار SMA برای یک آرایه از اعداد
function calculateSMA(data: number[], period: number): number[] {
  const smaValues: number[] = []

  for (let i = period - 1; i < data.length; i++) {
    const sum = data.slice(i - period + 1, i + 1).reduce((acc, val) => acc + val, 0)
    smaValues.push(sum / period)
  }

  return smaValues
}

// تابع محاسبه مقدار EMA برای یک آرایه از اعداد
function calculateEMA(data: number[], period: number): number[] {
  const emaValues: number[] = []
  const multiplier = 2 / (period + 1)
  let ema = data.slice(0, period).reduce((acc, val) => acc + val, 0) / period

  emaValues.push(ema)

  for (let i = period; i < data.length; i++) {
    ema = (data[i] - ema) * multiplier + ema
    emaValues.push(ema)
  }

  return emaValues
}

// تابع محاسبه مقدار VWMA برای یک آرایه از اعداد
function calculateVWMA(data: number[], volume: number[], period: number): number[] {
  const vwmaValues: number[] = []

  for (let i = period - 1; i < data.length; i++) {
    let numerator = 0
    let denominator = 0

    for (let j = 0; j < period; j++) {
      numerator += data[i - j] * volume[i - j]
      denominator += volume[i - j]
    }

    vwmaValues.push(numerator / denominator)
  }

  return vwmaValues
}

function calculateRSI(data: number[], period: number): number[] {
  const rsiValues = []

  let avgGain = 0
  let avgLoss = 0

  // محاسبه تغییرات قیمت
  for (let i = 1; i < data.length; i++) {
    const priceChange = data[i] - data[i - 1]
    rsiValues.push(priceChange)

    if (priceChange > 0) {
      avgGain += priceChange
    } else {
      avgLoss -= priceChange // منفی‌ترین تغییرات را مثبت می‌کنیم
    }
  }

  avgGain /= period
  avgLoss /= period

  // محاسبه RSI بر اساس متغیرهای محاسبه شده
  for (let i = period; i < rsiValues.length; i++) {
    const priceChange = rsiValues[i]
    if (priceChange > 0) {
      avgGain = (avgGain * (period - 1) + priceChange) / period
      avgLoss = (avgLoss * (period - 1)) / period
    } else {
      avgLoss = (avgLoss * (period - 1) - priceChange) / period
      avgGain = (avgGain * (period - 1)) / period
    }

    const relativeStrength = avgGain / avgLoss
    const rsi = 100 - 100 / (1 + relativeStrength)
    rsiValues[i] = rsi
  }

  return rsiValues.slice(period) // برگرداندن مقادیر RSI بدون تغییرات اولیه
}

const API_URL = process.env.REACT_APP_API_URL

type Props = {
  show: boolean
  chartData: any
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const CreateChartModal = ({show, chartData, handleClose}: Props) => {
  // chart
  const chartContainerRef = useRef(null)
  const chartContainerRsiRef = useRef(null)

  useEffect(() => {
    if (chartContainerRef.current && show) {
      const chart = createChart(chartContainerRef.current, {width: 800, height: 400})
      const candlestickSeries = chart.addCandlestickSeries()

      const fetchData = async () => {
        try {
          const response = await axios.post(`${API_URL}/fetch_chart_data`, {
            chartData: chartData,
          })
          const data = response.data
          // تنظیم داده‌های کندل‌ها در چارت
          candlestickSeries.setData(data.candle)

          let priod = 10
          if (
            (data.strategy > 6 && data.strategy < 13) ||
            (data.strategy > 36 && data.strategy < 43) ||
            (data.strategy > 66 && data.strategy < 73)
          ) {
            priod = 20
          } else if (
            (data.strategy > 12 && data.strategy < 19) ||
            (data.strategy > 42 && data.strategy < 49) ||
            (data.strategy > 72 && data.strategy < 79)
          ) {
            priod = 50
          } else if (
            (data.strategy > 18 && data.strategy < 25) ||
            (data.strategy > 48 && data.strategy < 55) ||
            (data.strategy > 78 && data.strategy < 85)
          ) {
            priod = 100
          } else if (
            (data.strategy > 24 && data.strategy < 31) ||
            (data.strategy > 54 && data.strategy < 61) ||
            (data.strategy > 84 && data.strategy < 91)
          ) {
            priod = 200
          } else if (
            (data.strategy > 0 && data.strategy < 7) ||
            (data.strategy > 30 && data.strategy < 37) ||
            (data.strategy > 60 && data.strategy < 67)
          ) {
            priod = 10
          }
          // محاسبه مقدار SMA
          const closePrices = data.candle.map((candle: Candle) => candle.close)
          const volumePrices = data.candle.map((candle: Candle) => candle.volume)

          let values: number[] = []

          if (data.strategy > 90 && data.strategy < 97) {
            values = calculateRSI(closePrices, 14)
            if (chartContainerRsiRef.current && show) {
              // ایجاد یک چارت جدید برای نمایش نمودار RSI
              const rsiChart = createChart(chartContainerRsiRef.current, {width: 800, height: 200})
              const rsiLineSeries = rsiChart.addLineSeries({
                color: 'green', // رنگ خط نمودار RSI
                lineWidth: 2,
                priceLineVisible: false,
              })

              rsiLineSeries.setData(
                values.map((value, index) => ({
                  time: data.candle[index + 14 - 1].time,
                  value,
                }))
              )


              // Draw a vertical line at the specified time and RSI value
              const verticalLineRsi = rsiChart.addLineSeries({
                priceFormat: {
                  type: 'price',
                },
                lineWidth: 4,
                color: 'orange',
              })

              let lineValue = 50
              if (data.strategy == 91 || data.strategy == 95) {
                lineValue = 70
              }else if(data.strategy == 92 || data.strategy == 96) {
                lineValue = 30
              }
              // تنظیم خط افقی بر روی زمان data.strategyTimestamp و RSI متناظر
              verticalLineRsi.setData([
                {time: data.strategyTimestamp, value: lineValue},
              ])

              rsiChart.applyOptions({
                crosshair: {
                  mode: CrosshairMode.Normal,
                },
                timeScale: {
                  visible: true,
                  timeVisible: true,
                  secondsVisible: true,
                },
              })
            }
          } else {
            if (data.strategy < 31) {
              values = calculateSMA(closePrices, priod)
            } else if (data.strategy > 30 && data.strategy < 61) {
              values = calculateEMA(closePrices, priod)
            } else if (data.strategy > 60 && data.strategy < 91) {
              values = calculateVWMA(closePrices, volumePrices, priod)
            }
            // رسم خط مووینگ
            chart
              .addLineSeries({
                color: 'blue', // رنگ خط
                lineWidth: 2,
                priceLineVisible: false,
              })
              .setData(
                values.map((value, index) => ({
                  time: data.candle[index + priod - 1].time,
                  value,
                }))
              ) // نکته: index + priod -1 برای تطبیق با تاریخ‌های معتبر

            // Draw a vertical line at the specified time and price
            const verticalLine = chart.addLineSeries({
              priceFormat: {
                type: 'price',
              },
              lineWidth: 4,
              color: 'orange',
              title: 'Strategy Point',
              priceLineVisible: false,
            })
            verticalLine.setData([{time: data.strategyTimestamp, value: data.strategyData}])
          }
        } catch (error) {
          console.error('Error fetching data: ', error)
        }
      }

      fetchData()

      chart.applyOptions({
        crosshair: {
          mode: CrosshairMode.Normal,
        },
        timeScale: {
          visible: true,
          timeVisible: true,
          secondsVisible: true,
        },
      })

      return () => {
        chart.remove()
      }
    }
  }, [show, chartData])

  return createPortal(
    <Modal
      id='kt_modal_create_chart'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className='modal-header'>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTIcon className='fs-1' iconName='cross' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Stepper */}

        <div
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
          {/* begin::Aside*/}
          <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 flex-column'>
            {/* begin::Nav*/}
            <div className='chart-container' ref={chartContainerRef}></div>
            <div className='chart-container' ref={chartContainerRsiRef}></div>

            {/* end::Nav*/}
          </div>
          {/* begin::Aside*/}

          {/*begin::Content */}
          <div className='flex-row-fluid py-lg-5 px-lg-15'></div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export {CreateChartModal}
