import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { AuthModel, UserModel } from './_models'
import * as authHelper from './AuthHelpers'
import { getUserByToken, logoutUser } from './_requests'
import { WithChildren } from '../../../../_metronic/helpers'


type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => { },
  currentUser: undefined,
  setCurrentUser: () => { },
  logout: () => { },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    }
  }

  const logout = async () => {
    const { data } = await logoutUser()
    if (data) {
      saveAuth(undefined)
      setCurrentUser(undefined)
      authHelper.removeAuth()
    }
  }

  return (
    <AuthContext.Provider value={{ auth, saveAuth, currentUser, setCurrentUser, logout }}>
      {children}
    </AuthContext.Provider>
  )
}




const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, setCurrentUser } = useAuth()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const didRequest = useRef(false) 

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        if (didRequest.current) return; 
        didRequest.current = true; 

      
        if (auth?.api_token) {
          const { data: user } = await getUserByToken()
          if (user) {
            setCurrentUser(user)
          }
        }
      } catch (error) {
        console.error('خطا در فرآیند لاگین:', error)
        logout()
      } finally {
        setShowSplashScreen(false)
      }
    }

    initializeAuth()
  }, [auth, logout, setCurrentUser]) 

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}



export { AuthProvider, AuthInit, useAuth }
