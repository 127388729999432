import {FC, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {PageTitle} from '../../_metronic/layout/core'
import {KTIcon} from '../../_metronic/helpers'
import axios from 'axios'
import {format} from 'date-fns'
import {CreateChartModal} from '../../_metronic/partials'

const API_URL = process.env.REACT_APP_API_URL

const RadarPage: FC = () => {
  const {strategieId} = useParams()
  const [data, setData] = useState<any[]>([])
  const [pageTitle, setPageTitle] = useState('STRATEGIES')
  const [loading, setLoading] = useState(true)
  const [showChartModal, setShowChartModal] = useState<boolean>(false)
  const [chartData, setChartData] = useState<any[]>([])

  const handleShowChartClick = (
    symbol: any,
    time_frame: any,
    strategieId: any,
    close_price: any,
    timestamp: any
  ) => {
    const data = [symbol, time_frame, strategieId, close_price, timestamp]
    setShowChartModal(true)
    setChartData(data)
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const response = await axios.post(`${API_URL}/radar_strategies`, {
          strategieId: strategieId,
        })

        setData(response.data.data)
        setLoading(false)
      } catch (error) {
        console.error('Error fetching data: ', error)
        setLoading(false)
      }
    }

    fetchData()
    const interval = setInterval(fetchData, 600000) // 1 h
    return () => clearInterval(interval)
  }, [strategieId])

  useEffect(() => {
    let newTitle = ''

    switch (strategieId) {
      case '1':
        newTitle = 'Breakup SMA(10) Strategy'
        break
      case '2':
        newTitle = 'Breakdown SMA(10) Strategy'
        break
      case '3':
        newTitle = 'Price above SMA(10) Strategy'
        break
      case '4':
        newTitle = 'Price bellow SMA(10) Strategy'
        break
      case '5':
        newTitle = 'Pullback from above SMA(10) Strategy'
        break
      case '6':
        newTitle = 'Pullback from bellow SMA(10) Strategy'
        break
      case '7':
        newTitle = 'Breakup SMA(20) Strategy'
        break
      case '8':
        newTitle = 'Breakdown SMA(20) Strategy'
        break
      case '9':
        newTitle = 'Price above SMA(20) Strategy'
        break
      case '10':
        newTitle = 'Price bellow SMA(20) Strategy'
        break
      case '11':
        newTitle = 'Pullback from above SMA(20) Strategy'
        break
      case '12':
        newTitle = 'Pullback from bellow SMA(20) Strategy'
        break
      case '13':
        newTitle = 'Breakup SMA(50) Strategy'
        break
      case '14':
        newTitle = 'Breakdown SMA(50) Strategy'
        break
      case '15':
        newTitle = 'Price above SMA(50) Strategy'
        break
      case '16':
        newTitle = 'Price bellow SMA(50) Strategy'
        break
      case '17':
        newTitle = 'Pullback from above SMA(50) Strategy'
        break
      case '18':
        newTitle = 'Pullback from bellow SMA(50) Strategy'
        break
      case '19':
        newTitle = 'Breakup SMA(100) Strategy'
        break
      case '20':
        newTitle = 'Breakdown SMA(100) Strategy'
        break
      case '21':
        newTitle = 'Price above SMA(100) Strategy'
        break
      case '22':
        newTitle = 'Price bellow SMA(100) Strategy'
        break
      case '23':
        newTitle = 'Pullback from above SMA(100) Strategy'
        break
      case '24':
        newTitle = 'Pullback from bellow SMA(100) Strategy'
        break
      case '25':
        newTitle = 'Breakup SMA(200) Strategy'
        break
      case '26':
        newTitle = 'Breakdown SMA(200) Strategy'
        break
      case '27':
        newTitle = 'Price above SMA(200) Strategy'
        break
      case '28':
        newTitle = 'Price bellow SMA(200) Strategy'
        break
      case '29':
        newTitle = 'Pullback from above SMA(200) Strategy'
        break
      case '30':
        newTitle = 'Pullback from bellow SMA(200) Strategy'
        break
      case '31':
        newTitle = 'Breakup EMA(10) Strategy'
        break
      case '32':
        newTitle = 'Breakdown EMA(10) Strategy'
        break
      case '33':
        newTitle = 'Price above EMA(10) Strategy'
        break
      case '34':
        newTitle = 'Price bellow EMA(10) Strategy'
        break
      case '35':
        newTitle = 'Pullback from above EMA(10) Strategy'
        break
      case '36':
        newTitle = 'Pullback from bellow EMA(10) Strategy'
        break
      case '37':
        newTitle = 'Breakup EMA(20) Strategy'
        break
      case '38':
        newTitle = 'Breakdown EMA(20) Strategy'
        break
      case '39':
        newTitle = 'Price above EMA(20) Strategy'
        break
      case '40':
        newTitle = 'Price bellow EMA(20) Strategy'
        break
      case '41':
        newTitle = 'Pullback from above EMA(20) Strategy'
        break
      case '42':
        newTitle = 'Pullback from bellow EMA(20) Strategy'
        break
      case '43':
        newTitle = 'Breakup EMA(50) Strategy'
        break
      case '44':
        newTitle = 'Breakdown EMA(50) Strategy'
        break
      case '45':
        newTitle = 'Price above EMA(50) Strategy'
        break
      case '46':
        newTitle = 'Price bellow EMA(50) Strategy'
        break
      case '47':
        newTitle = 'Pullback from above EMA(50) Strategy'
        break
      case '48':
        newTitle = 'Pullback from bellow EMA(50) Strategy'
        break
      case '49':
        newTitle = 'Breakup EMA(100) Strategy'
        break
      case '50':
        newTitle = 'Breakdown EMA(100) Strategy'
        break
      case '51':
        newTitle = 'Price above EMA(100) Strategy'
        break
      case '52':
        newTitle = 'Price bellow EMA(100) Strategy'
        break
      case '53':
        newTitle = 'Pullback from above EMA(100) Strategy'
        break
      case '54':
        newTitle = 'Pullback from bellow EMA(100) Strategy'
        break
      case '55':
        newTitle = 'Breakup EMA(200) Strategy'
        break
      case '56':
        newTitle = 'Breakdown EMA(200) Strategy'
        break
      case '57':
        newTitle = 'Price above EMA(200) Strategy'
        break
      case '58':
        newTitle = 'Price bellow EMA(200) Strategy'
        break
      case '59':
        newTitle = 'Pullback from above EMA(200) Strategy'
        break
      case '60':
        newTitle = 'Pullback from bellow EMA(200) Strategy'
        break
      case '61':
        newTitle = 'Breakup VWMA(10) Strategy'
        break
      case '62':
        newTitle = 'Breakdown VWMA(10) Strategy'
        break
      case '63':
        newTitle = 'Price above VWMA(10) Strategy'
        break
      case '64':
        newTitle = 'Price bellow VWMA(10) Strategy'
        break
      case '65':
        newTitle = 'Pullback from above VWMA(10) Strategy'
        break
      case '66':
        newTitle = 'Pullback from bellow VWMA(10) Strategy'
        break
      case '67':
        newTitle = 'Breakup VWMA(20) Strategy'
        break
      case '68':
        newTitle = 'Breakdown VWMA(20) Strategy'
        break
      case '69':
        newTitle = 'Price above VWMA(20) Strategy'
        break
      case '70':
        newTitle = 'Price bellow VWMA(20) Strategy'
        break
      case '71':
        newTitle = 'Pullback from above VWMA(20) Strategy'
        break
      case '72':
        newTitle = 'Pullback from bellow VWMA(20) Strategy'
        break
      case '73':
        newTitle = 'Breakup VWMA(50) Strategy'
        break
      case '74':
        newTitle = 'Breakdown VWMA(50) Strategy'
        break
      case '75':
        newTitle = 'Price above VWMA(50) Strategy'
        break
      case '76':
        newTitle = 'Price bellow VWMA(50) Strategy'
        break
      case '77':
        newTitle = 'Pullback from above VWMA(50) Strategy'
        break
      case '78':
        newTitle = 'Pullback from bellow VWMA(50) Strategy'
        break
      case '79':
        newTitle = 'Breakup VWMA(100) Strategy'
        break
      case '80':
        newTitle = 'Breakdown VWMA(100) Strategy'
        break
      case '81':
        newTitle = 'Price above VWMA(100) Strategy'
        break
      case '82':
        newTitle = 'Price bellow VWMA(100) Strategy'
        break
      case '83':
        newTitle = 'Pullback from above VWMA(100) Strategy'
        break
      case '84':
        newTitle = 'Pullback from bellow VWMA(100) Strategy'
        break
      case '85':
        newTitle = 'Breakup VWMA(200) Strategy'
        break
      case '86':
        newTitle = 'Breakdown VWMA(200) Strategy'
        break
      case '87':
        newTitle = 'Price above VWMA(200) Strategy'
        break
      case '88':
        newTitle = 'Price bellow VWMA(200) Strategy'
        break
      case '89':
        newTitle = 'Pullback from above VWMA(200) Strategy'
        break
      case '90':
        newTitle = 'Pullback from bellow VWMA(200) Strategy'
        break
      case '91':
        newTitle = 'RSI above 70 Strategy'
        break
      case '92':
        newTitle = 'RSI below 30 Strategy'
        break
      case '93':
        newTitle = 'RSI break down 50 Strategy'
        break
      case '94':
        newTitle = 'RSI break up 50 Strategy'
        break
      case '95':
        newTitle = 'RSI break down 70 Strategy'
        break
      case '96':
        newTitle = 'RSI break up 30 Strategy'
        break
      default:
        newTitle = 'Unknown Strategy'
    }
    setPageTitle(newTitle)
    document.title = newTitle
  }, [strategieId])

  return (
    <>
      <PageTitle>{pageTitle}</PageTitle>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div className='row g-5 gx-xxl-8'>
          <div className='col-xxl-12'>
            <div className='card card-xxl-stretch mb-5 mb-xxl-8'>
              <div className='card-body py-3'>
                <div className='table-responsive'>
                  <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                    <thead>
                      <tr className='fw-bold text-muted'>
                        <th className='min-w-150px'>Pair</th>
                        <th className='min-w-140px'>Exchange</th>
                        <th className='min-w-120px'>TimeFrame</th>
                        <th className='min-w-120px'>Date</th>
                        <th className='min-w-120px'>Price</th>
                        <th className='min-w-100px text-end'>Chart</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length > 0 ? (
                        data.map((item, index) => (
                          <tr key={index}>
                            <td>{item.symbol}</td>
                            <td>
                              <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                Binance
                              </span>
                            </td>
                            <td>
                              <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                {item.time_frame}
                              </span>
                            </td>
                            <td>
                              <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                {format(new Date(parseInt(item.timestamp)), 'yyyy/MM/dd HH:mm')}
                              </span>
                            </td>
                            <td className='text-dark fw-bold text-hover-primary fs-6'>
                              $ {item.close_price}
                            </td>
                            <td className='text-end'>
                              <a
                                href='#'
                                onClick={() =>
                                  handleShowChartClick(
                                    item.symbol,
                                    item.time_frame,
                                    strategieId,
                                    item.close_price,
                                    item.timestamp
                                  )
                                }
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                <KTIcon iconName='chart' className='fs-3' />
                              </a>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={6}>No data available</td>
                        </tr>
                      )}
                      <CreateChartModal
                        show={showChartModal}
                        chartData={chartData}
                        handleClose={() => setShowChartModal(false)}
                      />
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export {RadarPage}
