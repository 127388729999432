import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { HeaderUserMenu, Chat, Search, ThemeModeSwitcher } from '../../../partials'
import { useLayout } from '../../core'
import { useAuth } from '../../../../app/modules/auth'

const itemClass = 'ms-1 ms-lg-3'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'fs-1'

const Navbar = () => {
  const { config } = useLayout()
  const { currentUser } = useAuth()

  return (
    <div className='app-navbar flex-shrink-0'>

      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <Chat />
      </div>

      {currentUser ? (
        <div className={clsx('app-navbar-item', itemClass)}>
          <div
            className={clsx('cursor-pointer symbol', userAvatarClass)}
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
          >
            <KTIcon iconName='user' className='fs-1' />
          </div>
          <HeaderUserMenu />
        </div>
      ) : (
        <div className={clsx('app-navbar-item', itemClass)}>
          <Link to='/auth'>
            <div className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'>
              <KTIcon iconName='user' className='fs-1' />
            </div>
          </Link>
        </div>
      )}
    </div>
  )
}

export { Navbar }
