import React, {FC} from 'react'
import {ErrorMessage, Field} from 'formik'

const Step1: FC = () => {
  return (
    <div className='w-100'>
      <div className='mb-0 fv-row'>
        <label className='d-flex align-items-center form-label mb-5 required'>
          نوع فعالیت فروشگاه را مشخص نمایید:
        </label>

        <div className='mb-0'>
          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  تولیدی
                </span>
                <span className='fs-6 fw-bold text-gray-400'>
                  توضیحات کوتاه در مورد تولیدی
                </span>
              </span>
            </span>

            <span className='form-check form-check-custom form-check-solid'>
              <Field className='form-check-input' type='radio' name='type' value='1' />
            </span>
          </label>

          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  بازرگانی
                </span>
                <span className='fs-6 fw-bold text-gray-400'>توضیحات کوتاه در مورد بازرگانی</span>
              </span>
            </span>

            <span className='form-check form-check-custom form-check-solid'>
              <Field className='form-check-input' type='radio' name='type' value='2' />
            </span>
          </label>

          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  خدمات
                </span>
                <span className='fs-6 fw-bold text-gray-400'>
                  توضیحات کوتاه در مورد خدمات
                </span>
              </span>
            </span>

            <span className='form-check form-check-custom form-check-solid'>
              <Field className='form-check-input' type='radio' name='type' value='3' />
            </span>
          </label>

          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  عمده فروشی
                </span>
                <span className='fs-6 fw-bold text-gray-400'>
                  توضیحات کوتاه در مورد عمده فروشی
                </span>
              </span>
            </span>

            <span className='form-check form-check-custom form-check-solid'>
              <Field className='form-check-input' type='radio' name='type' value='4' />
            </span>
          </label>

          <label className='d-flex flex-stack mb-0 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  خرده فروشی
                </span>
                <span className='fs-6 fw-bold text-gray-400'>
                  توضیحات کوتاه در مورد خرده فروشی
                </span>
              </span>
            </span>

            <span className='form-check form-check-custom form-check-solid'>
              <Field className='form-check-input' type='radio' name='type' value='5' />
            </span>
          </label>
        </div>
      </div>
    </div>
  )
}

export {Step1}
