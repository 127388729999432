import axios from 'axios'
import { ProductModel } from './ShopModels'

const API_URL = process.env.REACT_APP_API_URL


export const GET_PROUDCT_URL = `${API_URL}/get_proudct`


export function getProductById(productId: string) {
  return axios.post<ProductModel>(GET_PROUDCT_URL, {
    productId: productId,
  })
}
