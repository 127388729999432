import { FC, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { KTIcon } from '../../../../helpers'
import { ParentCategoryModel } from '../../../../../app/pages/shop/profile/ProfileModels'
import { getShopCategories } from '../../../../../app/pages/shop/profile/_requests'



const ToolbarExtended: FC = () => {
  const [category, setCategory] = useState<string>('')
  const [selectedState, setSelectedState] = useState<string>('')
  const [selectedCity, setSelectedCity] = useState<string>('')
  const [progress, setProgress] = useState<string>('')
  const [search, setSearch] = useState<string>('')
  const [categories, setCategories] = useState<ParentCategoryModel[]>([])
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true)

  const navigate = useNavigate();
  const location = useLocation()

  const [states, setStates] = useState<{ id: string; name: string }[]>([
    { id: 'آذربایجان شرقی', name: 'آذربایجان شرقی' },
    { id: 'آذربایجان غربی', name: 'آذربایجان غربی' },
    { id: 'اردبیل', name: 'اردبیل' },
    { id: 'اصفهان', name: 'اصفهان' },
    { id: 'ایلام', name: 'ایلام' },
    { id: 'بوشهر', name: 'بوشهر' },
    { id: 'تهران', name: 'تهران' },
    { id: 'چهارمحال و بختیاری', name: 'چهارمحال و بختیاری' },
    { id: 'خراسان جنوبی', name: 'خراسان جنوبی' },
    { id: 'خراسان رضوی', name: 'خراسان رضوی' },
    { id: 'خراسان شمالی', name: 'خراسان شمالی' },
    { id: 'خوزستان', name: 'خوزستان' },
    { id: 'زنجان', name: 'زنجان' },
    { id: 'سمنان', name: 'سمنان' },
    { id: 'سیستان و بلوچستان', name: 'سیستان و بلوچستان' },
    { id: 'فارس', name: 'فارس' },
    { id: 'قزوین', name: 'قزوین' },
    { id: 'قم', name: 'قم' },
    { id: 'کردستان', name: 'کردستان' },
    { id: 'کرمان', name: 'کرمان' },
    { id: 'کرمانشاه', name: 'کرمانشاه' },
    { id: 'کهگیلویه و بویراحمد', name: 'کهگیلویه و بویراحمد' },
    { id: 'گلستان', name: 'گلستان' },
    { id: 'گیلان', name: 'گیلان' },
    { id: 'لرستان', name: 'لرستان' },
    { id: 'مازندران', name: 'مازندران' },
    { id: 'مرکزی', name: 'مرکزی' },
    { id: 'هرمزگان', name: 'هرمزگان' },
    { id: 'همدان', name: 'همدان' },
    { id: 'یزد', name: 'یزد' },
    { id: 'البرز', name: 'البرز' },
  ])

  const citiesByState: { [key: string]: string[] } = {
    'آذربایجان شرقی': ['آذرشهر', 'اسکو', 'اهر', 'بستان‌آباد', 'بناب', 'تبریز', 'جلفا', 'چاراویماق', 'سراب', 'شبستر', 'مراغه'],
    'آذربایجان غربی': ['ارومیه', 'اشنویه', 'بوکان', 'پیرانشهر', 'تکاب', 'چالدران', 'خوی', 'سردشت', 'سلماس', 'شاهین‌دژ', 'ماکو', 'مهاباد', 'میاندوآب', 'نقده'],
    'اردبیل': ['اردبیل', 'بیله‌سوار', 'پارس‌آباد', 'خلخال', 'کوثر', 'گِرمی', 'مِشگین‌شهر', 'نَمین', 'نیر'],
    'اصفهان': ['آران و بیدگل', 'اردستان', 'اصفهان', 'برخوار و میمه', 'تیران و کرون', 'چادگان', 'خمینی‌شهر', 'خوانسار', 'سمیرم', 'شهرضا', 'سمیرم سفلی', 'فریدن', 'فریدون‌شهر', 'فلاورجان', 'کاشان', 'گلپایگان', 'لنجان', 'مبارکه', 'نائین', 'نجف‌آباد', 'نطنز'],
    'ایلام': ['آبدانان', 'ایلام', 'ایوان', 'دره‌شهر', 'دهلران', 'شیروان و چرداول', 'مهران'],
    'بوشهر': ['بوشهر', 'تنگستان', 'جم', 'دشتستان', 'دشتی', 'دیر', 'دیلم', 'کنگان', 'گناوه'],
    'تهران': ['اسلام‌شهر', 'پاکدشت', 'تهران', 'دماوند', 'رباط‌کریم', 'ری', 'ساوجبلاغ', 'شمیرانات', 'شهریار', 'فیروزکوه', 'ورامین'],
    'چهارمحال و بختیاری': ['اردل', 'بروجن', 'شهرکرد', 'فارسان', 'کوهرنگ', 'لردگان'],
    'خراسان جنوبی': ['بیرجند', 'درمیان', 'سرایان', 'سربیشه', 'فردوس', 'قائنات', 'نهبندان'],
    'خراسان رضوی': ['بردسکن', 'تایباد', 'تربت جام', 'تربت حیدریه', 'چناران', 'خلیل‌آباد', 'خواف', 'درگز', 'رشتخوار', 'سبزوار', 'سرخس', 'فریمان', 'قوچان', 'کاشمر', 'کلات', 'گناباد', 'مشهد', 'مه ولات', 'نیشابور'],
    'خراسان شمالی': ['اسفراین', 'بجنورد', 'جاجرم', 'شیروان', 'فاروج', 'مانه و سملقان'],
    'خوزستان': ['آبادان', 'امیدیه', 'اندیمشک', 'اهواز', 'ایذه', 'باغ‌ملک', 'بندر ماهشهر', 'بهبهان', 'خرمشهر', 'دزفول', 'دشت آزادگان', 'رامشیر', 'رامهرمز', 'شادگان', 'شوش', 'شوشتر'],
    'زنجان': ['ابهر', 'ایجرود', 'خدابنده', 'خرمدره', 'زنجان', 'طارم', 'ماه‌نشان'],
    'سمنان': ['دامغان', 'سمنان', 'شاهرود', 'گرمسار', 'مهدی‌شهر'],
    'سیستان و بلوچستان': ['ایرانشهر', 'چابهار', 'خاش', 'دلگان', 'زابل', 'زاهدان', 'زهک', 'سراوان', 'سرباز', 'کنارک', 'نیک‌شهر'],
    'فارس': ['آباده', 'ارسنجان', 'استهبان', 'اقلید', 'بوانات', 'پاسارگاد', 'جهرم', 'خرم‌بید', 'خنج', 'داراب', 'زرین‌دشت', 'سپیدان', 'شیراز', 'فراشبند', 'فسا', 'فیروزآباد', 'قیر و کارزین', 'کازرون', 'لارستان', 'لامِرد', 'مرودشت', 'ممسنی', 'مهر', 'نی‌ریز'],
    'قزوین': ['آبیک', 'البرز', 'بوئین‌زهرا', 'تاکستان', 'قزوین'],
    'قم': ['قم'],
    'کردستان': ['بانه', 'بیجار', 'دیواندره', 'سروآباد', 'سقز', 'سنندج', 'قروه', 'کامیاران', 'مریوان'],
    'کرمان': ['بافت', 'بردسیر', 'بم', 'جیرفت', 'راور', 'رفسنجان', 'رودبار جنوب', 'زرند', 'سیرجان', 'شهر بابک', 'عنبرآباد', 'قلعه گنج', 'کرمان', 'کوهبنان', 'کهنوج', 'منوجان'],
    'کرمانشاه': ['اسلام‌آباد غرب', 'پاوه', 'ثلاث باباجانی', 'جوانرود', 'دالاهو', 'روانسر', 'سرپل ذهاب', 'سنقر', 'صحنه', 'قصر شیرین', 'کرمانشاه', 'کنگاور', 'گیلان غرب', 'هرسین'],
    'کهگیلویه و بویراحمد': ['بویراحمد', 'بهمئی', 'دنا', 'کهگیلویه', 'گچساران'],
    'گلستان': ['آزادشهر', 'آق‌قلا', 'بندر گز', 'ترکمن', 'رامیان', 'علی‌آباد', 'کردکوی', 'کلاله', 'گرگان', 'گنبد کاووس', 'مراوه‌تپه', 'مینودشت'],
    'گیلان': ['آستارا', 'آستانه اشرفیه', 'اَملَش', 'بندر انزلی', 'رشت', 'رضوانشهر', 'رودبار', 'رودسر', 'سیاهکل', 'شَفت', 'صومعه‌سرا', 'طوالش', 'فومَن', 'لاهیجان', 'لنگرود', 'ماسال'],
    'لرستان': ['ازنا', 'الیگودرز', 'بروجرد', 'پل‌دختر', 'خرم‌آباد', 'دورود', 'دلفان', 'سلسله', 'کوهدشت'],
    'مازندران': ['آمل', 'بابل', 'بابلسر', 'بهشهر', 'تنکابن', 'جویبار', 'چالوس', 'رامسر', 'ساری', 'سوادکوه', 'قائم‌شهر', 'گلوگاه', 'محمودآباد', 'نکا', 'نور', 'نوشهر'],
    'مرکزی': ['آشتیان', 'اراک', 'تفرش', 'خمین', 'دلیجان', 'زرندیه', 'ساوه', 'شازند', 'کمیجان', 'محلات'],
    'هرمزگان': ['ابوموسی', 'بستک', 'بندر عباس', 'بندر لنگه', 'جاسک', 'حاجی‌آباد', 'شهرستان خمیر', 'رودان', 'قشم', 'گاوبندی', 'میناب'],
    'همدان': ['اسدآباد', 'بهار', 'تویسرکان', 'رزن', 'کبودرآهنگ', 'ملایر', 'نهاوند', 'همدان'],
    'یزد': ['یزد'],
    'البرز': ['کرج', 'نظرآباد', 'فردیس', 'اشتهارد', 'هشتگرد', 'طالقان'],
  };

  useEffect(() => {
    document.body.setAttribute('data-kt-app-toolbar-fixed', 'true')

    // لود دسته‌بندی‌ها از دیتابیس
    const fetchCategories = async () => {
      try {
        const response = await getShopCategories()
        setCategories(response.data)
      } catch (error) {
        console.error('Error fetching categories:', error)
      }
    }

    fetchCategories()
  }, [])

  // تابع برای نمایش گزینه‌های دسته‌بندی
  const renderCategoryOptions = () => {
    return categories
      .filter((category) => !category.parent_id)
      .map((parentCategory) => (
        <optgroup key={parentCategory.id} label={parentCategory.name}>
          {parentCategory.children
            .filter((category) => category.parent_id == parentCategory.id)
            .map((childCategory) => (
              <option key={childCategory.id} value={childCategory.id}>
                {childCategory.name}
              </option>
            ))}
        </optgroup>
      ))
  }

  const handleStateChange = (state: string) => {
    setSelectedState(state);
    setSelectedCity('');
  };

  const renderStateOptions = () => {
    return states.map((state) => (
      <option key={state.id} value={state.id}>
        {state.name}
      </option>
    ));
  };

  const renderCityOptions = () => {
    if (!selectedState) return null;
    return citiesByState[selectedState]?.map((city) => (
      <option key={city} value={city}>
        {city}
      </option>
    ));
  };

  const buildUrl = () => {
    const params = new URLSearchParams()
    if (category) params.append('c', category)
    if (selectedState) params.append('l', selectedState)
    if (selectedCity) params.append('ll', selectedCity);
    if (progress) params.append('s', progress)
    if (search.length > 2) params.append('t', search)

    return `${location.pathname}?${params.toString()}`
  }

  // مقداردهی اولیه با توجه به URL
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    setCategory(params.get('c') || '')
    setSelectedState(params.get('l') || '')
    setSelectedCity(params.get('ll') || '')
    setProgress(params.get('s') || '')
    setSearch(params.get('t') || '')

    setIsInitialLoad(false)

  }, [])

  // به‌روزرسانی URL در نوار آدرس
  useEffect(() => {
    if (!isInitialLoad) {
      const url = buildUrl()
      navigate(url, { replace: true })
    }
  }, [category, selectedState, selectedCity, progress, search])

  return (
    <>
      <div className='d-flex align-items-center flex-shrink-0'>
        <div className='position-relative my-1'>
          <KTIcon
            iconName='magnifier'
            className='fs-3 text-gray-500 position-absolute top-50 translate-middle pe-0'
          />
          <input
            type='text'
            className='form-control form-control-sm form-control-solid w-100 pe-10'
            name='search'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder='جستجو در بازار...'
          />
        </div>
      </div>

      <div className='d-flex align-items-center overflow-auto'>
        <select
          className='form-select form-select-sm w-125px form-select-solid me-6'
          data-control='select2'
          data-placeholder='دسته‌بندی‌'
          data-hide-search='true'
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        >
          <option value=''>همه دسته‌بندی‌ها</option>
          {renderCategoryOptions()}
        </select>

        <div className='bullet bg-secondary h-35px w-1px mx-6'></div>


        <select
          className='form-select form-select-sm w-125px form-select-solid me-6'
          data-control='select2'
          data-placeholder='استان'
          data-hide-search='false'
          value={selectedState}
          onChange={(e) => handleStateChange(e.target.value)}
        >
          <option value=''>کل استان‌ها</option>
          {renderStateOptions()}
        </select>

        <div className='bullet bg-secondary h-35px w-1px mx-6'></div>

        <select
          className='form-select form-select-sm w-125px form-select-solid me-6'
          data-control='select2'
          data-placeholder='شهر'
          data-hide-search='false'
          value={selectedCity}
          onChange={(e) => setSelectedCity(e.target.value)}
        >
          <option value=''>کل شهرها </option>
          {renderCityOptions()}
        </select>


        <div className='bullet bg-secondary h-35px w-1px mx-6'></div>

        <select
          className='form-select form-select-sm w-125px form-select-solid me-6'
          data-control='select2'
          data-placeholder='جدیدترین'
          data-hide-search='true'
          value={progress}
          onChange={(e) => setProgress(e.target.value)}
        >
          <option value='newest'>جدیدترین</option>
          <option value='mostPopular'>محبوب‌ترین</option>
        </select>

        {/* <div className='d-flex align-items-center'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-light-primary me-3'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            title='نمایش جدولی'
          >
            <KTIcon iconName='element-11' className='fs-3 text-primary' />
          </button>

          <button
            type='button'
            className='btn btn-sm btn-icon btn-light'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            title='نمایش سطری'
          >
            <KTIcon iconName='row-horizontal' className='fs-3 text-gray-400' />
          </button>
        </div> */}
      </div>
    </>
  )
}

export { ToolbarExtended }
