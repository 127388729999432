import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const NEW_SHOP_URL = `${API_URL}/new_shop`

export function newShop(
  type: string,
  businessName: string,
  businessUsername: string,
  state: string,
  city: string,
  phone: string,
  address: string,
  latitude: string,
  longitude: string,
) {
  return axios.post(NEW_SHOP_URL, {
    type: type,
    businessName: businessName,
    businessUsername: businessUsername,
    state: state,
    city: city,
    phone: phone,
    address: address,
    lat: latitude,
    lng: longitude,
  })
}
