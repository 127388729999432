import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getSearches } from './_requests'
import { ProductModel } from './Models'
import { Products } from './components/productCard'

const Search: React.FC = () => {
  const location = useLocation()
  const [products, setProducts] = useState<ProductModel[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchPostsByParams = async () => {
      setLoading(true);
      setError(null);

      const params = new URLSearchParams(location.search);
      try {
        const response = await getSearches(params.toString());
        setProducts(response.data);
      } catch (err) {
        setError('خطا در دریافت پست‌ها');
      } finally {
        setLoading(false);
      }
    };

    fetchPostsByParams();
  }, [location.search]);

  return (
    <>
      {loading ? (
        <div>در حال بارگذاری...</div>
      ) : error ? (
        <div className="alert alert-danger">{error}</div>
      ) : products.length === 0 ? (
        <div className="alert alert-info">محصولی مطابق فیلترها یافت نشد!</div>
      ) : (
        <Products products={products} />
      )}
    </>
  );
}

export { Search };
