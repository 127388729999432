import axios from 'axios'
import { ShopModel } from './Models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_SHOP_URL = `${API_URL}/get_shops`



export function getShops(data: any) {
  return axios.post<ShopModel[]>(GET_SHOP_URL, data);
}

