/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Strategies</span>
        </div>
      </div>
      <SidebarMenuItemWithSub to='#' title='SMA'>
        <SidebarMenuItem to='/radar/1' title='Breakup SMA(10)' hasBullet={true} />
        <SidebarMenuItem to='/radar/2' title='Breakdown SMA(10)' hasBullet={true} />
        <SidebarMenuItem to='/radar/3' title='Price above SMA(10)' hasBullet={true} />
        <SidebarMenuItem to='/radar/4' title='Price bellow SMA(10)' hasBullet={true} />
        <SidebarMenuItem to='/radar/5' title='Pullback above SMA(10)' hasBullet={true} />
        <SidebarMenuItem to='/radar/6' title='Pullback bellow SMA(10)' hasBullet={true} />
        <SidebarMenuItem to='/radar/7' title='Breakup SMA(20)' hasBullet={true} />
        <SidebarMenuItem to='/radar/8' title='Breakdown SMA(20)' hasBullet={true} />
        <SidebarMenuItem to='/radar/9' title='Price above SMA(20)' hasBullet={true} />
        <SidebarMenuItem to='/radar/10' title='Price bellow SMA(20)' hasBullet={true} />
        <SidebarMenuItem to='/radar/11' title='Pullback above SMA(20)' hasBullet={true} />
        <SidebarMenuItem to='/radar/12' title='Pullback bellow SMA(20)' hasBullet={true} />
        <SidebarMenuItem to='/radar/13' title='Breakup SMA(50)' hasBullet={true} />
        <SidebarMenuItem to='/radar/14' title='Breakdown SMA(50)' hasBullet={true} />
        <SidebarMenuItem to='/radar/15' title='Price above SMA(50)' hasBullet={true} />
        <SidebarMenuItem to='/radar/16' title='Price bellow SMA(50)' hasBullet={true} />
        <SidebarMenuItem to='/radar/17' title='Pullback above SMA(50)' hasBullet={true} />
        <SidebarMenuItem to='/radar/18' title='Pullback bellow SMA(50)' hasBullet={true} />
        <SidebarMenuItem to='/radar/19' title='Breakup SMA(100)' hasBullet={true} />
        <SidebarMenuItem to='/radar/20' title='Breakdown SMA(100)' hasBullet={true} />
        <SidebarMenuItem to='/radar/21' title='Price above SMA(100)' hasBullet={true} />
        <SidebarMenuItem to='/radar/22' title='Price bellow SMA(100)' hasBullet={true} />
        <SidebarMenuItem to='/radar/23' title='Pullback above SMA(100)' hasBullet={true} />
        <SidebarMenuItem to='/radar/24' title='Pullback bellow SMA(100)' hasBullet={true} />
        <SidebarMenuItem to='/radar/25' title='Breakup SMA(200)' hasBullet={true} />
        <SidebarMenuItem to='/radar/26' title='Breakdown SMA(200)' hasBullet={true} />
        <SidebarMenuItem to='/radar/27' title='Price above SMA(200)' hasBullet={true} />
        <SidebarMenuItem to='/radar/28' title='Price bellow SMA(200)' hasBullet={true} />
        <SidebarMenuItem to='/radar/29' title='Pullback above SMA(200)' hasBullet={true} />
        <SidebarMenuItem to='/radar/30' title='Pullback bellow SMA(200)' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='#' title='EMA'>
        <SidebarMenuItem to='/radar/31' title='Breakup EMA(10)' hasBullet={true} />
        <SidebarMenuItem to='/radar/32' title='Breakdown EMA(10)' hasBullet={true} />
        <SidebarMenuItem to='/radar/33' title='Price above EMA(10)' hasBullet={true} />
        <SidebarMenuItem to='/radar/34' title='Price bellow EMA(10)' hasBullet={true} />
        <SidebarMenuItem to='/radar/35' title='Pullback above EMA(10)' hasBullet={true} />
        <SidebarMenuItem to='/radar/36' title='Pullback bellow EMA(10)' hasBullet={true} />
        <SidebarMenuItem to='/radar/37' title='Breakup EMA(20)' hasBullet={true} />
        <SidebarMenuItem to='/radar/38' title='Breakdown EMA(20)' hasBullet={true} />
        <SidebarMenuItem to='/radar/39' title='Price above EMA(20)' hasBullet={true} />
        <SidebarMenuItem to='/radar/40' title='Price bellow EMA(20)' hasBullet={true} />
        <SidebarMenuItem to='/radar/41' title='Pullback above EMA(20)' hasBullet={true} />
        <SidebarMenuItem to='/radar/42' title='Pullback bellow EMA(20)' hasBullet={true} />
        <SidebarMenuItem to='/radar/43' title='Breakup EMA(50)' hasBullet={true} />
        <SidebarMenuItem to='/radar/44' title='Breakdown EMA(50)' hasBullet={true} />
        <SidebarMenuItem to='/radar/45' title='Price above EMA(50)' hasBullet={true} />
        <SidebarMenuItem to='/radar/46' title='Price bellow EMA(50)' hasBullet={true} />
        <SidebarMenuItem to='/radar/47' title='Pullback above EMA(50)' hasBullet={true} />
        <SidebarMenuItem to='/radar/48' title='Pullback bellow EMA(50)' hasBullet={true} />
        <SidebarMenuItem to='/radar/49' title='Breakup EMA(100)' hasBullet={true} />
        <SidebarMenuItem to='/radar/50' title='Breakdown EMA(100)' hasBullet={true} />
        <SidebarMenuItem to='/radar/51' title='Price above EMA(100)' hasBullet={true} />
        <SidebarMenuItem to='/radar/52' title='Price bellow EMA(100)' hasBullet={true} />
        <SidebarMenuItem to='/radar/53' title='Pullback above EMA(100)' hasBullet={true} />
        <SidebarMenuItem to='/radar/54' title='Pullback bellow EMA(100)' hasBullet={true} />
        <SidebarMenuItem to='/radar/55' title='Breakup EMA(200)' hasBullet={true} />
        <SidebarMenuItem to='/radar/56' title='Breakdown EMA(200)' hasBullet={true} />
        <SidebarMenuItem to='/radar/57' title='Price above EMA(200)' hasBullet={true} />
        <SidebarMenuItem to='/radar/58' title='Price bellow EMA(200)' hasBullet={true} />
        <SidebarMenuItem to='/radar/59' title='Pullback above EMA(200)' hasBullet={true} />
        <SidebarMenuItem to='/radar/60' title='Pullback bellow EMA(200)' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='#' title='VWMA'>
      <SidebarMenuItem to='/radar/61' title='Breakup VWMA(10)' hasBullet={true} />
        <SidebarMenuItem to='/radar/62' title='Breakdown VWMA(10)' hasBullet={true} />
        <SidebarMenuItem to='/radar/63' title='Price above VWMA(10)' hasBullet={true} />
        <SidebarMenuItem to='/radar/64' title='Price bellow VWMA(10)' hasBullet={true} />
        <SidebarMenuItem to='/radar/65' title='Pullback above VWMA(10)' hasBullet={true} />
        <SidebarMenuItem to='/radar/66' title='Pullback bellow VWMA(10)' hasBullet={true} />
        <SidebarMenuItem to='/radar/67' title='Breakup VWMA(20)' hasBullet={true} />
        <SidebarMenuItem to='/radar/68' title='Breakdown VWMA(20)' hasBullet={true} />
        <SidebarMenuItem to='/radar/69' title='Price above VWMA(20)' hasBullet={true} />
        <SidebarMenuItem to='/radar/70' title='Price bellow VWMA(20)' hasBullet={true} />
        <SidebarMenuItem to='/radar/71' title='Pullback above VWMA(20)' hasBullet={true} />
        <SidebarMenuItem to='/radar/72' title='Pullback bellow VWMA(20)' hasBullet={true} />
        <SidebarMenuItem to='/radar/73' title='Breakup VWMA(50)' hasBullet={true} />
        <SidebarMenuItem to='/radar/74' title='Breakdown VWMA(50)' hasBullet={true} />
        <SidebarMenuItem to='/radar/75' title='Price above VWMA(50)' hasBullet={true} />
        <SidebarMenuItem to='/radar/76' title='Price bellow VWMA(50)' hasBullet={true} />
        <SidebarMenuItem to='/radar/77' title='Pullback above VWMA(50)' hasBullet={true} />
        <SidebarMenuItem to='/radar/78' title='Pullback bellow VWMA(50)' hasBullet={true} />
        <SidebarMenuItem to='/radar/79' title='Breakup VWMA(100)' hasBullet={true} />
        <SidebarMenuItem to='/radar/80' title='Breakdown VWMA(100)' hasBullet={true} />
        <SidebarMenuItem to='/radar/81' title='Price above VWMA(100)' hasBullet={true} />
        <SidebarMenuItem to='/radar/82' title='Price bellow VWMA(100)' hasBullet={true} />
        <SidebarMenuItem to='/radar/83' title='Pullback above VWMA(100)' hasBullet={true} />
        <SidebarMenuItem to='/radar/84' title='Pullback bellow VWMA(100)' hasBullet={true} />
        <SidebarMenuItem to='/radar/85' title='Breakup VWMA(200)' hasBullet={true} />
        <SidebarMenuItem to='/radar/86' title='Breakdown VWMA(200)' hasBullet={true} />
        <SidebarMenuItem to='/radar/87' title='Price above VWMA(200)' hasBullet={true} />
        <SidebarMenuItem to='/radar/88' title='Price bellow VWMA(200)' hasBullet={true} />
        <SidebarMenuItem to='/radar/89' title='Pullback above VWMA(200)' hasBullet={true} />
        <SidebarMenuItem to='/radar/90' title='Pullback bellow VWMA(200)' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='#' title='RSI'>
      <SidebarMenuItem to='/radar/91' title='RSI above 70' hasBullet={true} />
      <SidebarMenuItem to='/radar/92' title='RSI below 30' hasBullet={true} />
      <SidebarMenuItem to='/radar/93' title='RSI break down 50' hasBullet={true} />
      <SidebarMenuItem to='/radar/94' title='RSI break up 50' hasBullet={true} />
      <SidebarMenuItem to='/radar/95' title='RSI break down 70' hasBullet={true} />
      <SidebarMenuItem to='/radar/96' title='RSI break up 30' hasBullet={true} />
      </SidebarMenuItemWithSub>
    </>
  )
}

export {SidebarMenuMain}
